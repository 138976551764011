// components/ConfirmDialog.js
import React from "react";
import { createRoot } from "react-dom/client";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

export const confirmDialog = ({
  title,
  message,
  confirmLabel = "Confirmar",
  cancelLabel = "Cancelar",
}) => {
  return new Promise((resolve) => {
    const dialog = document.createElement("div");
    document.body.appendChild(dialog);
    const root = createRoot(dialog);

    const handleClose = (value) => {
      root.unmount();
      document.body.removeChild(dialog);
      resolve(value);
    };

    root.render(
      <Dialog open onClose={() => handleClose(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="error">
            {cancelLabel}
          </Button>
          <Button onClick={() => handleClose(true)} color="black">
            {confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  });
};
