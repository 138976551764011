// src/components/MainComponent.js
import React, { useState, useEffect,useMemo } from 'react';
import { Wizard } from './Wizard';
import Filters from './Filters';
import FiltersDatabase from './FiltersDatabase';

import Visualization from './Visualization';
import Toolbar from './Toolbar';
import GroupingControls from './GroupingControls';
import { configurationService } from './configurationService';
import { Box, CircularProgress, Typography,Divider,TextField } from '@mui/material';
import {notificacionService} from '../../services/apiService';
import  FiltersDatabase_v2  from './Filtersdatabase_v2';
import { useParams } from 'react-router-dom';


import { useLoading } from '../../contexts/LoadingContext';


const MainComponent =  () => {


  const [wizardOpen, setWizardOpen] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [dataSource, setDataSource] = useState(null);
  const [reportName, setReportName] = useState('');
  const [visualizationType, setVisualizationType] = useState('Table');
  const [filters, setFilters] = useState({});
  const [groupBy, setGroupBy] = useState('');
  const [showCount, setShowCount] = useState(true);
  const [showPercentage, setShowPercentage] = useState(false);
  const [showNested, setShowNested] = useState(true);
  const [savedConfigs, setSavedConfigs] = useState([]);
  const [currentConfigId, setCurrentConfigId] = useState(null);
  const [currentGroupedData, setCurrentGroupedData] = useState([]);
  const [savedColors, setSavedColors] = useState({});
  // const [filtersWithTypes, setFiltersWithTypes] = useState([]);
  const { setIsLoading, setLoadingMessage } = useLoading(); 

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [partialData, setPartialData] = useState(false);

  const { configId } = useParams(); // Obtiene el configId de la URL


  const [grouping, setGrouping] = useState({
    groupBy: '',
    showCount: true,
    showPercentage: false,
    showNested: true,
  });

  const [panelSettings, setPanelSettings] = useState({
    showChart: false,
    chartType: 'BarChart',
  });

  const clearConfigurations = () => {
    setReportName('');
    setDataSource(null);
    setSelectedFields([]);
    setFilters({});
    setGrouping({
      groupBy: '',
      showCount: true,
      showPercentage: false,
      showNested: true,
    });
    setVisualizationType('Table');
    setPanelSettings({
      showChart: false,
      chartType: 'BarChart',
    });
    setCurrentConfigId(null);
    setCurrentGroupedData([]);
    setSavedColors({});
    setData([]);
  };

useEffect(() => {
    clearConfigurations();
  }, []);

  // const fetchVehiculosAndAlertas = async () => {
  //   try {
  //     const vehiculosgps = await notificacionService.getAlertas();
  //     const alertasgps = await notificacionService.getVehiculo();
  //     console.log('Vehiculos GPS:', vehiculosgps);
  //     console.log('Alertas GPS:', alertasgps);
  //   } catch (error) {
  //     console.error('Error fetching vehiculos and alertas:', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchVehiculosAndAlertas();
  // }, []);


  useEffect(() => {
    loadSavedConfigs();
  }, []);

  const baseStructure = {
    "DatosAlerta.NombreTipo": {
      type: "string",
      visible: true,
      label: "Tipo de Alerta",
      control: "select",
      displayField: "DatosAlerta.NombreTipo",
      valueField: "DatosAlerta.Tipo",
      relationship: "alertType",
      options: [] // Will be populated dynamically
    },
    "DatosAlerta.Tipo": {
      type: "string",
      visible: false,
      label: "Tipo",
      control: "hidden",
      relationship: "alertType"
    },
    "EconomicoAlias": {
      type: "string",
      visible: true,
      label: "Económico",
      control: "select",
      displayField: "EconomicoAlias",
      valueField: "VehiculoUid",
      relationship: "vehicle",
      options: []
    },
    "VehiculoUid": {
      type: "string",
      visible: false,
      label: "Vehículo",
      control: "hidden",
      relationship: "vehicle"
    },
    "Message": {
      type: "string",
      visible: true,
      label: "Mensaje",
      control: "text"
    },
    "Fecha": {
      type: "Date",
      visible: true,
      label: "Fecha",
      control: "datetime"
    },
    "Status": {
      type: "string",
      visible: true,
      label: "Estado",
      control: "select",
      options: ["Activo", "Inactivo", "Pendiente"]
    },
    "CountAlert": {
      type: "number",
      visible: true,
      label: "Contador",
      control: "number"
    },
    "NombreChofer": {
      type: "string",
      visible: true,
      label: "Chofer",
      control: "text"
    },
    // Add other fields as needed
  };

  const assignDataTypes = (filters, baseStructure) => {
    if (Array.isArray(filters)) {
      return filters.map(field => ({
        nombre: field,
        tipoDato: baseStructure[field]?.type || "string",
        label: baseStructure[field]?.label || field,
        visible: baseStructure[field]?.visible ?? true,
        control: baseStructure[field]?.control || "text",
        options: baseStructure[field]?.options || [],
        valueField: baseStructure[field]?.valueField,
        displayField: baseStructure[field]?.displayField,
        relationship: baseStructure[field]?.relationship
      }));
    } else if (typeof filters === 'object' && filters !== null) {
      return Object.keys(filters).map(field => ({
        nombre: field,
        tipoDato: baseStructure[field]?.type || "string",
        label: baseStructure[field]?.label || field,
        visible: baseStructure[field]?.visible ?? true,
        control: baseStructure[field]?.control || "text",
        options: baseStructure[field]?.options || [],
        valueField: baseStructure[field]?.valueField,
        displayField: baseStructure[field]?.displayField,
        relationship: baseStructure[field]?.relationship
      }));
    }
    return [];
  };

  // const filtersWithTypes = useMemo(() => {
  //   const result = assignDataTypes(selectedFields, baseStructure);
  //   console.log('🔍 filtersWithTypes actualizado:', result);
  //   return result;
  // }, [selectedFields, baseStructure]);
  const filtersWithTypes = useMemo(() => {
    const result = assignDataTypes(selectedFields, baseStructure);
    //console.log('🔍 filtersWithTypes actualizado:', result);
    return result;
  }, [selectedFields, baseStructure]);

  useEffect(() => {
   // console.log('📄 Estado de filters:', filters);
    //console.log('📄 Estado de filtersWithTypes:', filtersWithTypes);
  }, [selectedFields, filtersWithTypes]);

 



  const getConfigData = () => ({
    name: reportName,
    dataSource,
    selectedFields,
    filters,
    filtersWithTypes,
    grouping: {
      groupBy,
      showCount,
      showPercentage,
      showNested,
      colors: currentGroupedData.reduce((acc, group) => {
        acc[group.groupKey] = group.color;
        return acc;
      }, {})
    },
    visualization: {
      type: visualizationType,
      chartType: panelSettings.chartType,
      showChart: panelSettings.showChart,
    },
  });

 // Función para manejar el cambio de agrupación
 const handleSetGroupBy = (value) => {
  setGroupBy(value);
  if (!value) {
    // Si se desagrupa, volvemos a la vista de tabla
    setVisualizationType('Table');
  }
};


const loadSavedConfigs = async () => {
  try {
    const configs = await configurationService.loadConfigs();
    setSavedConfigs(configs);
    return configs;
  } catch (error) {
    console.error('Error loading configurations:', error);
    return [];
  }
};

const handleSaveConfig = async () => {
  try {
   // console.log('🔄 Iniciando guardado de configuración...');
    
    const configToSave = {
      name: reportName,
      dataSource,
      selectedFields,
      filters,
      filtersWithTypes,
      grouping: {
        groupBy,
        showCount,
        showPercentage,
        showNested,
        chartSettings: panelSettings // Incluir configuración de panel
      },
      visualization: {
        type: visualizationType,
      },
    };

    //console.log('📦 Configuración a guardar:', configToSave);

    if (currentConfigId) {
      //console.log('🔄 Actualizando configuración existente:', currentConfigId);
      await configurationService.updateConfig(currentConfigId, configToSave);
    } else {
      //console.log('➕ Creando nueva configuración');
      const savedConfig = await configurationService.saveConfig(configToSave);
      setCurrentConfigId(savedConfig.id);
      //console.log('✅ Nueva configuración creada:', savedConfig.id);
    }
    
    await loadSavedConfigs();
    //console.log('✅ Configuración guardada exitosamente');
    
  } catch (error) {
    console.error('❌ Error al guardar configuración:', error);
  }
};



const handleLoadConfig = async (configId, configs =[]) => {
  try {
    clearConfigurations();
    console.log('🔄 Cargando configuración:', configId);
    console.log('📦 Configuraciones guardadas:', savedConfigs);
    // Determinar qué conjunto de configuraciones usar
    const effectiveConfigs = Array.isArray(configs) && configs.length > 0 ? configs : savedConfigs;
    console.log('📦 Configuraciones utilizadas:', effectiveConfigs);

    const config = effectiveConfigs.find(c => c.id === configId);

    //const config = savedConfigs.find(c => c.id === configId);
    
    if (config) {
      console.log('📦 Configuración encontrada:', JSON.stringify(config, null, 2));
      
      setReportName(config.name);
      setDataSource(config.dataSource);
      setSelectedFields(config.selectedFields);
      setFilters(config.filters || {});
      
      //assignDataTypes(config.selectedFields || {}, baseStructure);

      if (config.filtersWithTypes) {
        // Maneja la estructura según sea necesario
        //console.log('🔧 Filtros con tipos de datos:', config.filtersWithTypes);
      }

      // Cargar configuración de agrupación
      const groupingConfig = config.grouping || {};
      setGroupBy(groupingConfig.groupBy || '');
      setShowCount(groupingConfig.showCount ?? true);
      setShowPercentage(groupingConfig.showPercentage ?? false);
      setShowNested(groupingConfig.showNested ?? true);
           
      // Cargar configuración de panel desde visualization
      if (config.visualization) {
        setPanelSettings({
          showChart: config.visualization.showChart ?? false,
          chartType: config.visualization.chartType || 'BarChart',
        });       
      }

      if (config.grouping?.colors) {
        setSavedColors(config.grouping.colors);
      }

      setVisualizationType(config.visualization.type);
      setCurrentConfigId(config.id);
      
      console.log('✅ Configuración cargada exitosamente');
    }
  } catch (error) {
    console.error('❌ Error al cargar configuración:', error);
  }
};

const handleDeleteConfig = async (configId) => {
  try {
    console.log('🗑️ Eliminando configuración:', configId);
    await configurationService.deleteConfig(configId);
    await loadSavedConfigs(); // Refresh configs list
    console.log('✅ Configuración eliminada exitosamente');
  } catch (error) {
    console.error('❌ Error al eliminar configuración:', error);
  }
};


useEffect(() => {
  const loadInitialConfig = async () => {
    console.log("Config ID:", configId);
    const configs = await loadSavedConfigs(); // Ahora debería devolver un array
    console.log("Configuraciones cargadas en useEffect:", configs);
    if (configId && Array.isArray(configs)) {
      const configExists = configs.some(c => c.id === configId);
      console.log(`¿Existe la configuración con id ${configId}?`, configExists);
      if (configExists) {
        await handleLoadConfig(configId, configs); // Pass configurations
      } else {
        console.warn(`La configuración con id ${configId} no existe.`);
      }
    }
  };

  loadInitialConfig();
}, [configId]);


const formatFechaLocal = (fecha) => {
  const pad = (num) => (num < 10 ? '0' + num : num);
  return `${fecha.getFullYear()}-${pad(fecha.getMonth() + 1)}-${pad(fecha.getDate())} ${pad(fecha.getHours())}:${pad(fecha.getMinutes())}:${pad(fecha.getSeconds())}`;
};

const dataSourceFunctions = {
  notificacionService: async (params) => {
    // Si esta función no requiere parámetros
    return await notificacionService.getNotificacionesproductivo(
      params.startDate,
      params.endDate,
      params.limit,
      params.skip,
      params.fields,
      params.sort,
      params.VehiculoUid,
      params.Tipo 
    );

  },
  notificationomisiones: async (params) => {
    // Esta función requiere varios parámetros
    return await notificacionService.getNotificacionesOmisiones(
      params.startDate,
      params.endDate,
      params.limit,
      params.skip,
      params.fields,
      params.sort,
      params.VehiculoUid,
      params.Tipo /*startDate, endDate, limit = 1, skip = 0, 
      fields = [], sort = 'FechaHora_Notificacion',
      VehiculoUid,Tipo */
    );
  },
  // Puedes agregar más dataSources y sus funciones correspondientes aquí
};

const fetchData = async () => {
  setIsLoading(true);
  setLoadingMessage('Cargando datos...');
  setLoading(true);
  setError(null);
  setPartialData(false);
  try {
    let accumulatedData = [];
    let skip = 0;
    const limit = 500;
    const fieldsToRetrieve = selectedFields;
    const sortBy = 'Fecha';

    // Generar fechas basadas en filtros
    // const fechaStart = filters['Fecha_start'] 
    //   ? new Date(filters['Fecha_start']).toISOString().slice(0, 19).replace('T', ' ') 
    //   : new Date().toISOString().slice(0, 19).replace('T', ' ');
    // const fechaEnd = filters['Fecha_end'] 
    //   ? new Date(filters['Fecha_end']).toISOString().slice(0, 19).replace('T', ' ') 
    //   : new Date().toISOString().slice(0, 19).replace('T', ' ');
    const fechaStart = filters['Fecha_start'] 
      ? formatFechaLocal(new Date(filters['Fecha_start']))
      : formatFechaLocal(new Date());
    const fechaEnd = filters['Fecha_end'] 
      ? formatFechaLocal(new Date(filters['Fecha_end']))
      : formatFechaLocal(new Date());

      const vehiculos = filters.vehiculo ? filters.vehiculo.map(v => v.VehiculoUId) : [];
      //const alertas = filters.alerta ? filters.alerta.map(a => a.TipoAlerta) : [];
      const alertas = filters.alerta || [];

      const commonParams = {
        startDate: fechaStart,
        endDate: fechaEnd,
        limit,
        skip,
        fields: selectedFields,
        sort: sortBy,
        VehiculoUid: vehiculos,
        Tipo: alertas,
      };
  
      // Seleccionar la función correspondiente al dataSource
      const fetchFunction = dataSourceFunctions[dataSource];
  
      if (!fetchFunction) {
        throw new Error(`Fuente de datos desconocida: ${dataSource}`);
      }

      

    let hasMore = true;

    while (hasMore) {
      try {
        // const response = await notificacionService.getNotificacionesproductivo(
        //   fechaStart,
        //   fechaEnd,
        //   limit,
        //   skip,
        //   fieldsToRetrieve,
        //   sortBy,
        //   vehiculos,
        //     alertas
        // );

        commonParams.skip = skip;

        let response;

        // Llamar a la función con o sin parámetros según sea necesario
        if (dataSource === 'notificacionService') {
          response = await fetchFunction(commonParams);
        } else {
          response = await fetchFunction(commonParams);
        }


        if (response && response.data) {
          accumulatedData = [...accumulatedData, ...response.data];
          skip += limit;

          if (accumulatedData.length >= response.pagination.total) {
            hasMore = false;
          }
        } else {
          hasMore = false;
        }
      } catch (pageError) {
        console.error('Error fetching page:', pageError);
        setPartialData(true);
        hasMore = false;
      }
    }

    setData(accumulatedData);
    //updateFilterOptions(accumulatedData);

  } catch (err) {
    console.error('Error fetching data:', err);
    setError('Failed to load data. Please try again.');
  } finally {
    setLoading(false);
    setIsLoading(false);
    setLoadingMessage('');
   
  }
};

// const updateFilterOptions = (data) => {
//   const economicoAliasSet = new Set();
//   const vehiculoUidMap = new Map();
//   const nombreTipoSet = new Set();
//   const tipoMap = new Map();

//   data.forEach(item => {
//     if (item.EconomicoAlias && item.VehiculoUid) {
//       economicoAliasSet.add(item.EconomicoAlias);
//       vehiculoUidMap.set(item.EconomicoAlias, item.VehiculoUid);
//     }
//     if (item.DatosAlerta?.NombreTipo && item.DatosAlerta?.Tipo !== undefined) {
//       nombreTipoSet.add(item.DatosAlerta.NombreTipo);
//       tipoMap.set(item.DatosAlerta.NombreTipo, item.DatosAlerta.Tipo);
//     }
//   });

//   // Actualizar opciones en baseStructure
//   baseStructure["EconomicoAlias"].options = Array.from(economicoAliasSet).map(name => ({
//     value: vehiculoUidMap.get(name),
//     label: name,
//   }));
//   baseStructure["EconomicoAlias"].control = 'select';

//   baseStructure["DatosAlerta.NombreTipo"].options = Array.from(nombreTipoSet).map(name => ({
//     value: tipoMap.get(name),
//     label: name,
//   }));
//   baseStructure["DatosAlerta.NombreTipo"].control = 'select';
// };

const updateFilterOptions = (data) => {
  const economicoAliasSet = new Set();
  const vehiculoUidMap = new Map();
  const nombreTipoSet = new Set();
  const tipoMap = new Map();

  data.forEach(item => {
    if (item.EconomicoAlias && item.VehiculoUid) {
      economicoAliasSet.add(item.EconomicoAlias);
      vehiculoUidMap.set(item.EconomicoAlias, item.VehiculoUid);
    }
    if (item.DatosAlerta?.NombreTipo && item.DatosAlerta?.Tipo !== undefined) {
      nombreTipoSet.add(item.DatosAlerta.NombreTipo);
      tipoMap.set(item.DatosAlerta.NombreTipo, item.DatosAlerta.Tipo);
    }
  });

  // Actualizar opciones en baseStructure
  baseStructure["EconomicoAlias"].options = Array.from(economicoAliasSet).map(name => ({
    value: vehiculoUidMap.get(name),
    label: name,
  }));
  baseStructure["EconomicoAlias"].control = 'select';

  baseStructure["DatosAlerta.NombreTipo"].options = Array.from(nombreTipoSet).map(name => ({
    value: tipoMap.get(name),
    label: name,
  }));
  baseStructure["DatosAlerta.NombreTipo"].control = 'select';
};

  return (
    <div>


      <Toolbar
        onOpenWizard={() => setWizardOpen(true)}
        onSelectVisualization={setVisualizationType}
        onSaveConfig={handleSaveConfig}
        onLoadConfig={handleLoadConfig}
        hasConfiguration={selectedFields.length > 0}
        savedConfigs={savedConfigs}
        currentConfigId={currentConfigId}
        getConfigData={getConfigData}    
        onDeleteConfig={handleDeleteConfig}
   
      />
      <Wizard
        open={wizardOpen}
        onClose={() => setWizardOpen(false)}
        // onSubmit={(name, dataSource, fields) => {
        //   setReportName(name);
        //   setDataSource(dataSource);
        //   setSelectedFields(fields);
        //   setFilters({});
        //   setVisualizationType('Table');
        // }}
        onSubmit={(name, selectedDataSource, fields) => {
          setReportName(name);
          setDataSource(selectedDataSource);
          setSelectedFields(fields);
          setFilters({});
          setGrouping({
            groupBy: '',
            showCount: true,
            showPercentage: false,
            showNested: true,
          });
          setVisualizationType('Table');
          setPanelSettings({
            showChart: false,
            chartType: 'BarChart',
          });
        }}
      />
      {selectedFields.length > 0 && (
        <>
          
          {/* <FiltersDatabase fields={selectedFields} filters={filters} setFilters={setFilters} filtersWithTypes={filtersWithTypes}/> */}
          {/* <FiltersDatabase 
            fields={selectedFields}
            filtersWithTypes={filtersWithTypes} 
            filters={filters} 
            setFilters={setFilters}
            onSearch={fetchData}
          /> */}

          <FiltersDatabase_v2 fields={selectedFields} filters={filters}
           setFilters={setFilters} filtersWithTypes={filtersWithTypes}  
           onSearch={fetchData} dataSource={dataSource}  configId={configId} />




          {/* {process.env.REACT_APP_SUPERUSERACTIVATE === 'true' && (
          <>
            <Filters fields={selectedFields} filters={filters} setFilters={setFilters} filtersWithTypes={filtersWithTypes}/>
          </> )} */}

          {process.env.REACT_APP_SUPERUSERACTIVATE === 'true' && (
          <>
              <GroupingControls
                fields={selectedFields}
                groupBy={groupBy}
                setGroupBy={setGroupBy}
                showCount={showCount}
                setShowCount={setShowCount}
                showPercentage={showPercentage}
                setShowPercentage={setShowPercentage}
                showNested={showNested}
                setShowNested={setShowNested}
              />
            </> )} 
          <Visualization
            type={visualizationType}
            data={data}
            dataSource={dataSource}
            fields={selectedFields}
            filters={filters}
            grouping={{
              groupBy,
              showCount,
              showPercentage,
              showNested,
            }}
            setType={setVisualizationType}
            panelSettings={panelSettings}
            setPanelSettings={setPanelSettings}
            updatePanelSettings={setPanelSettings} 
            setGroupedData={setCurrentGroupedData}
            savedColors={savedColors} 
            //onConvertView={handleConvertView}
          />
        </>
      )}
    </div>
  );
};

export default MainComponent;