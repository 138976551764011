// src/components/Filters.js
import React, { useEffect } from 'react';
import { 
  Paper,
  TextField,
  Box,
  Chip,
  Typography
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { motion } from 'framer-motion';

export const Filters = ({ filtersWithTypes, filters, setFilters }) => {

  useEffect(() => {
    const initializeDates = () => {
      filtersWithTypes.forEach(({ nombre, tipoDato }) => {
        if (tipoDato.toLowerCase() === 'date') {
          const today = new Date();
          const startOfDay = new Date(today.setHours(0, 0, 0, 0));
          const endOfDay = new Date(today.setHours(23, 59, 59, 999));
          setFilters(prev => ({
            ...prev,
            [`${nombre}_start`]: startOfDay,
            [`${nombre}_end`]: endOfDay,
          }));
        }
      });
    };

    initializeDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value,
    }));
    console.log(`Filtro actualizado: ${field} = ${value}`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Paper 
          elevation={3}
          sx={{ p: 2, mb: 2 }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>Filters</Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexWrap: 'wrap',
              alignItems: 'center'
            }}
          >
            {filtersWithTypes.map(({ nombre, tipoDato }) => (
              tipoDato.toLowerCase() === 'date' ? (
                <Box key={nombre} sx={{ display: 'flex', gap: 1 }}>
                  <DateTimePicker
                    label={`${nombre} inicio`}
                    value={filters[`${nombre}_start`] || null}
                    onChange={(newValue) => handleInputChange(`${nombre}_start`, newValue)}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                  <DateTimePicker
                    label={`${nombre} final`}
                    value={filters[`${nombre}_end`] || null}
                    onChange={(newValue) => handleInputChange(`${nombre}_end`, newValue)}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </Box>
              ) : (
                <TextField
                  key={nombre}
                  label={nombre}
                  variant="outlined"
                  size="small"
                  value={filters[nombre] || ''}
                  onChange={(e) => handleInputChange(nombre, e.target.value)}
                  sx={{ minWidth: 200 }}
                />
              )
            ))}
          </Box>
          <Box sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {Object.entries(filters).map(([field, value]) => (
              value && (
                <Chip
                  key={field}
                  label={
                    field.includes('_start') || field.includes('_end') 
                      ? `${field.replace('_start','').replace('_end','')}: ${new Date(value).toLocaleString()}`
                      : `${field}: ${value}`
                  }
                  onDelete={() => handleInputChange(field, null)}
                />
              )
            ))}
          </Box>
        </Paper>
      </motion.div>
    </LocalizationProvider>
  );
};

export default Filters;

//   return (
//     <LocalizationProvider dateAdapter={AdapterDateFns}>
//       <motion.div
//         initial={{ opacity: 0, y: -20 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.5 }}
//       >
//         <Paper 
//           elevation={3}
//           sx={{ p: 2, mb: 2 }}
//         >
//           <Typography variant="h6" sx={{ mb: 2 }}>Filters</Typography>
//           <Box
//             sx={{
//               display: 'flex',
//               gap: 2,
//               flexWrap: 'wrap',
//               alignItems: 'center'
//             }}
//           >
//             {filtersWithTypes.map((field) => (
//               isDateField(field) ? (
//                 <Box key={field} sx={{ display: 'flex', gap: 1 }}>
//                   <DatePicker
//                     label="Fecha inicial"
//                     value={filters[`${field}_start`] || null}
//                     onChange={(newValue) => handleInputChange(`${field}_start`, newValue)}
//                     renderInput={(params) => <TextField {...params} size="small" />}
//                   />
//                   <DatePicker
//                     label="Fecha final"
//                     value={filters[`${field}_end`] || null}
//                     onChange={(newValue) => handleInputChange(`${field}_end`, newValue)}
//                     renderInput={(params) => <TextField {...params} size="small" />}
//                   />
//                 </Box>
//               ) : (
//                 <TextField
//                   key={field}
//                   label={field}
//                   variant="outlined"
//                   size="small"
//                   value={filters[field] || ''}
//                   onChange={(e) => handleInputChange(field, e.target.value)}
//                   sx={{ minWidth: 200 }}
//                 />
//               )
//             ))}
//           </Box>
//           <Box sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
//             {Object.entries(filtersWithTypes).map(([field, value]) => (
//               value && (
//                 <Chip
//                   key={field}
//                   label={isDateField(field) ? `${field.replace('_start','').replace('_end','')}: ${new Date(value).toLocaleDateString()}` : `${field}: ${value}`}
//                   onDelete={() => handleInputChange(field, null)}
//                 />
//               )
//             ))}
//           </Box>
//         </Paper>
//       </motion.div>
//     </LocalizationProvider>
//   );
// };

// export default Filters;

// // src/components/Filters.js
// import React from 'react';
// import { 
//   Paper,
//   TextField,
//   Box,
//   Chip,
//   Typography
// } from '@mui/material';
// import { motion } from 'framer-motion';

// export const Filters = ({ fields, filters, setFilters }) => {
//   const handleInputChange = (field, value) => {
//     setFilters(prev => ({
//       ...prev,
//       [field]: value,
//     }));
//     console.log(`Filtro actualizado: ${field} = ${value}`);
//   };

//   return (
//     <motion.div
//       initial={{ opacity: 0, y: -20 }}
//       animate={{ opacity: 1, y: 0 }}
//       transition={{ duration: 0.5 }}
//     >
//       <Paper 
//         elevation={3}
//         sx={{ p: 2, mb: 2 }}
//       >
//         <Typography variant="h6" sx={{ mb: 2 }}>Filters</Typography>
//         <Box
//           sx={{
//             display: 'flex',
//             gap: 2,
//             flexWrap: 'wrap',
//             alignItems: 'center'
//           }}
//         >
//           {fields.map((field) => (
//             <TextField
//               key={field}
//               label={field}
//               variant="outlined"
//               size="small"
//               value={filters[field] || ''}
//               onChange={(e) => handleInputChange(field, e.target.value)}
//               sx={{ minWidth: 200 }}
//             />
//           ))}
//         </Box>
//         <Box sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
//           {Object.entries(filters).map(([field, value]) => (
//             value && (
//               <Chip
//                 key={field}
//                 label={`${field}: ${value}`}
//                 onDelete={() => handleInputChange(field, '')}
//               />
//             )
//           ))}
//         </Box>
//       </Paper>
//     </motion.div>
//   );
// };

// export default Filters;
