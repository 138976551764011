class WebSocketService {
    constructor() {
      this.ws = null;
      this.reconnectAttempts = 0;
      this.maxReconnectAttempts = 20;
      this.baseReconnectTimeout = 5000;
      this.maxReconnectTimeout = 30000;
      this.pingInterval = null;
      this.pingTimeout = null;
      this.isIntentionalClosure = false;
      this.messageQueue = [];
      this.connectionState = 'disconnected';
      this.lastMessageTime = null;
      this.currentCallback = null; // Almacenar el callback actual
    }
  
    connect(onMessageCallback) {
      if (this.connectionState === 'connecting') {
        console.log('Ya hay un intento de conexión en progreso.');
        return;
      }
  
      if (this.ws?.readyState === WebSocket.OPEN) {
        console.log('WebSocket ya está conectado.');
        return;
      }
  
      this.connectionState = 'connecting';
      this.isIntentionalClosure = false;
      this.currentCallback = onMessageCallback; // Guardar el callback
  
      try {
        console.log('Intentando conectar WebSocket...');
        //this.ws = new WebSocket('wss://websocket.quro.com.mx:30506/events');
        let uid_empresa = localStorage.getItem('uuid_empresa');
        this.ws = new WebSocket(`wss://websocket.quro.com.mx:30506/events?companyId=${uid_empresa}`);
        this.setupWebSocket(onMessageCallback);
      } catch (error) {
        console.error('Error al crear WebSocket:', error);
        this.handleConnectionFailure();
      }
    }
  
    setupWebSocket(onMessageCallback) {
      this.ws.onopen = () => {
        console.log('Conectado al servicio de alertas');
        this.connectionState = 'connected';
        this.reconnectAttempts = 0;
        //this.startPingInterval();
        this.processMessageQueue();
        if (this.onStatusChange) {
          this.onStatusChange('connected');
        }
      };
  
      this.ws.onmessage = (event) => {
        try {
          this.lastMessageTime = Date.now();
          const data = JSON.parse(event.data);
          // Si es una respuesta al ping, limpiamos el timeout
          if (data.type === 'pong') {
            // if (this.pingTimeout) {
            //   clearTimeout(this.pingTimeout);
            //   this.pingTimeout = null;
            // }
          } else {
            console.log('Mensaje recibido:', data);
            onMessageCallback(data);
          }
        } catch (error) {
          console.error('Error al procesar mensaje:', error);
        }
      };
  
      this.ws.onclose = (event) => {
        //this.clearPingInterval();
        this.connectionState = 'disconnected';
        
        if (!this.isIntentionalClosure) {
          console.log(`Conexión cerrada. Código: ${event.code}, Razón: ${event.reason}`);
          this.handleConnectionFailure();
        } else {
          console.log('Conexión cerrada intencionalmente');
          console.log('Close code:', event.code);
          console.log('Clean closure:', event.wasClean);
        }
      };
  
      this.ws.onerror = (error) => {
        console.error('Error en WebSocket:', error);
        if (this.onStatusChange) {
          this.onStatusChange('error');
        }
      };
    }
  
    // startPingInterval() {
    //   this.pingInterval = setInterval(() => {
    //     if (this.ws?.readyState === WebSocket.OPEN) {
    //       try {
    //         this.ws.send(JSON.stringify({ type: 'ping' }));
            
    //         this.pingTimeout = setTimeout(() => {
    //           console.log('Ping timeout - reconectando...');
    //           this.handleConnectionFailure();
    //         }, 5000);
    //       } catch (error) {
    //         console.error('Error al enviar ping:', error);
    //         this.handleConnectionFailure();
    //       }
    //     }
    //   }, 30000);
    // }
  
    // clearPingInterval() {
    //   if (this.pingInterval) {
    //     clearInterval(this.pingInterval);
    //     this.pingInterval = null;
    //   }
    //   if (this.pingTimeout) {
    //     clearTimeout(this.pingTimeout);
    //     this.pingTimeout = null;
    //   }
    // }
  
    handleConnectionFailure() {
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        const timeout = Math.min(
          this.baseReconnectTimeout * Math.pow(1.5, this.reconnectAttempts),
          this.maxReconnectTimeout
        );
        
        this.reconnectAttempts++;
        console.log(
          `Reintentando conexión ${this.reconnectAttempts}/${this.maxReconnectAttempts} ` +
          `en ${timeout/1000} segundos...`
        );
        
        setTimeout(() => {
          this.connect(this.currentCallback);
        }, timeout);
      } else {
        console.error('Se alcanzó el máximo número de intentos de reconexión');
        this.emitReconnectionFailed();
      }
    }
  
    send(message) {
      if (this.ws?.readyState === WebSocket.OPEN) {
        try {
          this.ws.send(JSON.stringify(message));
          return true;
        } catch (error) {
          console.error('Error al enviar mensaje:', error);
          this.messageQueue.push(message);
          return false;
        }
      } else {
        this.messageQueue.push(message);
        return false;
      }
    }
  
    processMessageQueue() {
      while (this.messageQueue.length > 0 && this.ws?.readyState === WebSocket.OPEN) {
        const message = this.messageQueue.shift();
        this.send(message);
      }
    }
  
    disconnect() {
      this.isIntentionalClosure = true;
      //this.clearPingInterval();
      if (this.ws) {
        try {
          this.ws.close(1000, 'Cierre intencional');
        } catch (error) {
          console.error('Error al cerrar WebSocket:', error);
        }
      }
      this.connectionState = 'disconnected';
      this.messageQueue = [];
      this.currentCallback = null;
    }
  
    getConnectionState() {
      return {
        state: this.connectionState,
        reconnectAttempts: this.reconnectAttempts,
        lastMessageTime: this.lastMessageTime,
        queuedMessages: this.messageQueue.length
      };
    }
  
    emitReconnectionFailed() {
      const event = new CustomEvent('websocketReconnectionFailed', {
        detail: {
          attempts: this.reconnectAttempts,
          timestamp: new Date().toISOString()
        }
      });
      window.dispatchEvent(event);
    }
  
    reset() {
      this.disconnect();
      this.reconnectAttempts = 0;
      this.messageQueue = [];
      this.lastMessageTime = null;
      this.connectionState = 'disconnected';
      this.currentCallback = null;
    }
  }
  
  export const wsService = new WebSocketService();
  export { WebSocketService };