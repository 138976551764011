import {
  Autocomplete,
  Checkbox,
  TextField,
  Paper,
  Grid,
  FormControlLabel,
  Divider,
  Box,
  Button,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import { motion } from "framer-motion";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { geocercaService } from "../../../services/apiService";
import { useEffect, useState } from "react";
import { isEmptyArray } from "formik";

export default function ListaGeocercas({
  configuracion,
  actualizarComponente,
  handleGuardarPrincipal,
  idAlerta,
}) {
  /*Lista de Geocercas*/
  const [geocercas, setGeocercas] = useState([]);
  const loadGeocercas = async () => {
    try {
      const response = await geocercaService.getGeocercas("");
      if (response.statusCode === 200) {
        //setGeocercas(response.data);
        setGeocercas(response.data.filter((geocerca) => geocerca.status === 1));
      } else {
        console.error("❌ Error al cargar geocercas:", response);
      }
    } catch (error) {
      console.error("❌ Error cargando geocercas:", error);
    }
  };
  const [selectedGeocercas, setSelectedGeocercas] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelect = (geocerca) => {
    setSelectedGeocercas(geocerca);
  };
  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) setSelectedGeocercas([...geocercas]);
      else setSelectedGeocercas([]);
      return !prev;
    });
  };
  /*Lista de Geocercas*/

  function isEnableButton() {
    return selectedGeocercas.length > 0 && idAlerta !== "";
  }

  useEffect(() => {
    loadGeocercas();
  }, []);

  useEffect(() => {
    console.log(selectedGeocercas);
    actualizarComponente(
      "geocerca",
      selectedGeocercas.map((item) => {
        return { _id: `${item.id}`, type: item.puntos.type };
      })
    );
  }, [selectedGeocercas]);

  useEffect(() => {
    //Validamos si el componente fue limpiado
    if (isEmptyArray(configuracion.geocerca)) {
      //refrescamos el state si es necesario
      if (!isEmptyArray(selectedGeocercas)) {
        setSelectedGeocercas([]);
        setSelectAll(false);
      }
    } else if (geocercas.length > 0) {
      // const selectedFromConfig = configuracion.geocerca.map((configGeocerca) => { //antiguo
      //   const matchingGeocerca = geocercas.find(
      //     (geocerca) => geocerca.id === configGeocerca._id
      //   );
      //   if (matchingGeocerca) {
      //     return {
      //       ...matchingGeocerca,
      //       type: configGeocerca.type,
      //     };
      //   }
      //   return null;
      // }).filter((item) => item !== null);

      // cuando se obtiene configuración
      // Comparamos si las geocercas seleccionadas actuales coinciden con las de la configuración
      const selectedFromConfig = configuracion.geocerca
        .map((configGeocerca) => {
          const matchingGeocerca = geocercas.find(
            (geocerca) => geocerca.id === configGeocerca._id
          );
          return matchingGeocerca
            ? { ...matchingGeocerca, type: configGeocerca.type }
            : null;
        })
        .filter((item) => item !== null);

      console.log("selecteds", selectedFromConfig);

      // Verificamos si el estado actual ya tiene las geocercas seleccionadas
      const areEqual =
        selectedGeocercas.length === selectedFromConfig.length &&
        selectedGeocercas.every(
          (sel, index) => sel.id === selectedFromConfig[index].id
        );

      if (!areEqual) {
        setSelectedGeocercas(selectedFromConfig); // Actualizamos solo si es necesario

        // Notificamos al componente padre si el estado cambia
        actualizarComponente(
          "geocerca",
          selectedFromConfig.map((item) => ({
            _id: item.id,
            type: item.type,
          }))
        );
      }
    }
  }, [configuracion.geocerca, geocercas]);

  return (
    <Box
      height={310}
      pr={2}
      sx={{
        display: "flex",
        alignItems: "stretch",
        alignContent: "space-between",
      }}
    >
      <Box
        pt={1}
        maxWidth={500}
        minWidth={250}
        flexGrow={1}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-around"}
      >
        <Box display={"flex"} maxWidth={500} minWidth={250} flexGrow={1}>
          <Box flexGrow={1}>
            <Autocomplete
              id="checkboxes-geocerca"
              multiple
              options={geocercas}
              noOptionsText={"No hay coincidencias"}
              disableCloseOnSelect
              disableListWrap
              // value={selectedGeocercas} //antiguo
              value={selectedGeocercas.map((selected) =>
                geocercas.find((geocerca) => geocerca.id === selected.id)
              )}
              defaultValue={selectedGeocercas}
              // onChange={(_e, value, reason) => { //antiguo
              //   if (reason === "clear" || reason === "removeOption")
              //     setSelectAll(false);
              //   if (
              //     reason === "selectOption" &&
              //     value.length === geocercas.length
              //   )
              //     setSelectAll(true);
              //   handleSelect(value);
              // }}
              onChange={(_e, value, reason) => {
                switch (reason) {
                  case "clear":
                    setSelectAll(false);
                    setSelectedGeocercas([]);
                    break;
                  case "removeOption":
                    setSelectAll(false);
                    setSelectedGeocercas(value);
                    break;
                  case "selectOption":
                    if (value.length === geocercas.length) setSelectAll(true);
                    setSelectedGeocercas(value);
                    break;
                  default:
                    setSelectedGeocercas(value);
                }
              }}
              getOptionLabel={(option) => option.nombre}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option.nombre}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Geocercas"
                  placeholder="Selecciona una geocerca"
                />
              )}
              renderTags={(selectedOptions) => {
                return <></>;
              }}
              groupBy={() => ""}
              renderGroup={(params) => (
                <div key={params.key}>
                  <>
                    <Box
                      onMouseDown={(e) => e.preventDefault()} // prevent blur
                      pl={3.5}
                      py={0.5}
                    >
                      <FormControlLabel
                        onClick={(e) => {
                          e.preventDefault(); // prevent blur
                          handleToggleSelectAll();
                        }}
                        label="Seleccionar todos"
                        control={
                          <Checkbox
                            id="select-all-checkbox"
                            checked={selectAll}
                          />
                        }
                      />
                    </Box>
                    <Divider />
                  </>
                  {params.children}
                </div>
              )}
            />
          </Box>
          <Button
            variant="contained"
            sx={{
              marginLeft: 2,
              width: 55,
              height: 55,
              bgcolor: "#ea0000",
              color: "#ffffff",
              padding: "12px",
              fontSize: "1.1rem",
              fontWeight: 600,
              "&:hover": {
                bgcolor: "#000000",
              },
              "&.Mui-disabled": {
                bgcolor: "grey.600",
                color: "#ffffff",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <AddCircleIcon />
            </Box>
          </Button>
        </Box>
        <Box alignSelf={"flex-end"}>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            color="primary"
            type="button"
            sx={{ width: "150px", padding: 2 }}
            onClick={() => {
              handleGuardarPrincipal();
            }}
            disabled={!isEnableButton()}
          >
            Guardar
          </Button>
        </Box>
      </Box>

      <Box marginLeft={2} display="flex">
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            bgcolor: "red",
            width: "2px",
          }}
        />
      </Box>

      <Box marginLeft={2} flexGrow={1} pt={1}>
        <Grid
          container
          alignContent="flex-start"
          display={"flex"}
          direction={"column"}
          wrap="wrap"
          height={300}
          sx={{
            overflowX: geocercas.length >= 5 ? "auto" : "visible",
          }}
        >
          {selectedGeocercas.map((geocerca) => (
            <Grid
              item
              key={geocerca.id}
              p={2}
              width={"50%"}
              height={"20%"}
              border={1}
              borderColor={"grey.100"}
            >
              {geocerca.nombre}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
