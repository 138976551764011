// MapShapes.js
import React, { useEffect } from 'react';
import { useGoogleMap } from '@react-google-maps/api';

export const RenderCircle = ({ center, radius, name }) => {
  const map = useGoogleMap();

  useEffect(() => {
    if (!map) return;

    const circle = new window.google.maps.Circle({
      strokeColor: '#4285F4',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#4285F4',
      fillOpacity: 0.2,
      map,
      center,
      radius,
    });

    const label = new window.google.maps.marker.AdvancedMarkerElement({
      position: center,
      map,
      content: createLabel(name),
    });

    return () => {
      circle.setMap(null);
      label.setMap(null);
    };
  }, [map, center, radius, name]);

  return null;
};

export const RenderPolygon = ({ paths, name }) => {
  const map = useGoogleMap();

  useEffect(() => {
    if (!map) return;
    if (!Array.isArray(paths)) {
      console.error('paths debe ser un arreglo');
      return;
    }

    const polygon = new window.google.maps.Polygon({
      paths,
      fillColor: '#4285F4',
      fillOpacity: 0.2,
      strokeColor: '#4285F4',
      strokeOpacity: 1,
      strokeWeight: 2,
      map,
    });

    const bounds = new window.google.maps.LatLngBounds();
    paths.forEach(coord => bounds.extend(coord));
    const center = bounds.getCenter();

    const label = new window.google.maps.marker.AdvancedMarkerElement({
      position: center,
      map,
      content: createLabel(name),
    });

    return () => {
      polygon.setMap(null);
      label.setMap(null);
    };
  }, [map, paths, name]);

  return null;
};

const createLabel = (name) => {
  const div = document.createElement('div');
  div.innerHTML = `
    <div class="label-container">
      <div class="label-content">
        <span class="label-icon">📍</span>
        <span class="label-text">${name}</span>
      </div>
    </div>
  `;
  return div;
};