// src/components/ViewSelector.js
import React from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

const ViewSelector = ({ currentView, onChangeView, availableViews }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleViewChange = (viewType) => {
    onChangeView(viewType);
    handleMenuClose();
  };

  return (
    <>
      <Tooltip title="Cambiar Vista">
        <IconButton onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
      >
        {availableViews.map((view) => (
          <MenuItem
            key={view.type}
            selected={currentView === view.type}
            onClick={() => handleViewChange(view.type)}
          >
            {view.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ViewSelector;