import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  Divider,
  Dialog, // Para los modales
  DialogTitle, // Título del modal
  DialogContent, // Contenido principal del modal
  DialogContentText, // Texto dentro del modal
  DialogActions, // Botones de acción del modal

} from "@mui/material";
import { eventoService, RiesgoService } from "../../../services/apiService";
import { Repeat } from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';

const EVENTOS_TIEMPO = [
  "6091ba29662e2b2e74500a40",
  "6091ba1d662e2b2e74500a3d",
  "6091ba3e662e2b2e74500a43",
  "6189b3ea2a2e016f281d2a05",
];
const EVENTOS_TIPO_MARCADO = [
  "6091ba3e662e2b2e74500a43",
  "6189b3ea2a2e016f281d2a05",
  "6091ba52662e2b2e74500a46"
];
const EVENTOS_VECES_VELOCIDAD = ["6091ba52662e2b2e74500a46"];
const EVENTOS_DISTANCIA = ["6189b3ea2a2e016f281d2a05"];

const NuevaAlertaForm = ({
  idAlerta,
  configuracion,
  setConfiguracion,
  handleGuardarPrincipal,
  resetForm,
  viewNew // condicion para mostrar el boton nuevo o no
}) => {
  const [eventos, setEventos] = useState([]);
  const [riesgos, setRiesgos] = useState([]);
  const [errorNombre, setErrorNombre] = useState(""); // Estado para manejar errores en "Nombre"
  // Estado para el modal de cancelar
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  // Abrir y cerrar modal de cancelar
  const handleOpenCancelDialog = () => setCancelDialogOpen(true);
  const handleCloseCancelDialog = () => setCancelDialogOpen(false);
  // Estado para controlar la apertura del modal de guardar
const [saveDialogOpen, setSaveDialogOpen] = useState(false);

const handleOpenSaveDialog = () => setSaveDialogOpen(true);
const handleCloseSaveDialog = () => setSaveDialogOpen(false);

  const loadEventos = async () => {
    try {
      const response = await eventoService.getEventos();
      if (response && response.statusCode === 200) {
        setEventos(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error cargando eventos:", error);
    }
  };

  const loadRiesgos = async () => {
    try {
      const response = await RiesgoService.getRiesgos();
      if (response && response.statusCode === 200) {
        setRiesgos(response.data);
      }
    } catch (error) {
      console.error("Error cargando riesgos:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

      // Validación específica para el campo "Nombre"
      if (name === "nombre") {
        if (value.length > 50) {
          setErrorNombre("El nombre no puede tener más de 50 caracteres.");
        } else {
          setErrorNombre(""); // Resetea el error si la longitud es válida
        }
      }

    setConfiguracion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Manejar selección de tipo de alerta
  const handleSelectTipoAlerta = (eventoId) => {
    const eventoSeleccionado = eventos.find((evento) => evento.id === eventoId);

    let tipoMarcado = 0; // Valor por defecto para tipo_marcado

    // Asignar tipo_marcado basado en el tipo elegido
    if (eventoId === "6091ba1d662e2b2e74500a3d") { //Entrada
      tipoMarcado = 1; // Dentro
    } else if (eventoId === "6091ba29662e2b2e74500a40") { //Salida
      tipoMarcado = 2; // Fuera
    }

    setConfiguracion((prev) => ({
      ...prev,
      tipo_alerta: {
        _id: eventoSeleccionado.id,
        nombre: eventoSeleccionado.nombre,
        evento: eventoSeleccionado.eventos || [],
      },
      tipo_marcado: tipoMarcado, // Establecer tipo_marcado automáticamente
    }));
  };

  useEffect(() => {
    loadEventos();
    loadRiesgos();
  }, []);

  return (
    <Box
      sx={{
        borderRadius: 2,
        boxShadow: 1,
        backgroundColor: "white",
        my: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
        }}
      >
        {/**Titulo */}
        <Typography
          sx={{
            flexGrow: 1,
          }}
        >
          Nueva Alerta
        </Typography>

        {/** Botón NUEVO */}
        {viewNew && ( // ✅ Ajuste: Mostrar el botón si viewNew es true
          <Button
            variant="contained"
            color="primary"
            onClick={resetForm}
            startIcon={<AddIcon />}
            sx={{ width: "150px", padding: 2 }}
          >
            Nuevo
          </Button>
        )}
      </Box>
      <Box display="flex" alignItems="flex-start">
        <Box p={2} flex={1} sx={{ minWidth: "50%" }}>
          <Box display="flex" flexWrap="wrap" gap={2}>
            <TextField
              label="Nombre"
              name="nombre"
              variant="outlined"
              value={configuracion.nombre}
              onChange={handleChange}
              sx={{ minWidth: "50%" }}
              error={!!errorNombre} // Muestra un error si hay texto en errorNombre
              helperText={errorNombre} // Texto de ayuda bajo el campo
            />
            <FormControl variant="outlined" sx={{ minWidth: "47%" }}>
              <InputLabel>Riesgo</InputLabel>
              <Select
                label="Riesgo"
                name="riesgo" // Clave en el estado
                value={configuracion.riesgo}
                onChange={handleChange}
              >
            {riesgos
                      .sort((a, b) => {
                        // Definir prioridad de orden
                        const prioridad = { Alto: 1, Medio: 2, Bajo: 3 };
                        return prioridad[a.nombre] - prioridad[b.nombre];
                      })
                      .map((riesgo) => (
                        <MenuItem key={riesgo.id} value={riesgo.id}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1, // Espaciado entre el círculo y el texto
                            }}
                          >
                            {/* Circulito con color dinámico */}
                            <Box
                              sx={{
                                width: 12,
                                height: 12,
                                borderRadius: "50%",
                                backgroundColor:
                                  riesgo.nombre === "Alto"
                                    ? "#f54242"
                                    : riesgo.nombre === "Bajo"
                                    ? "#78db53"
                                    : riesgo.nombre === "Medio"
                                    ? "#dbd653"
                                    : "#a8a8a8", // Color predeterminado
                              }}
                            />
                            {/* Texto del riesgo */}
                            {riesgo.nombre}
                          </Box>
                        </MenuItem>
                      ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            bgcolor: "red",
            width: "2px",
          }}
        />
        <Box
          p={2}
          flex={1}
          sx={{
            minWidth: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start", // Alinear a la izquierda
            justifyContent: "flex-start", // No centrar en el eje vertical
            gap: 2,
          }}
        >
          <Box
            display="grid"
            gridTemplateColumns="repeat(2,1fr)"
            gap={2}
            width="100%"
          >
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Tipo</InputLabel>
              <Select
                label="Tipo"
                value={configuracion.tipo_alerta._id || ""}
                onChange={(e) => handleSelectTipoAlerta(e.target.value)} // Construye tipo_alerta
              >
                {eventos.map((evento) => (
                  <MenuItem key={evento.id} value={evento.id}>
                    {evento.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {configuracion.tipo_alerta._id  && (
           <>              
            {EVENTOS_TIEMPO.includes(configuracion.tipo_alerta._id) && (
              <Grid item xs={12} md={6}>
                <Tooltip title="Tiempo que debe transcurrir para que se genere la alerta">
                  <TextField
                    label="Tiempo (Min.)"
                    name="tiempo"
                    variant="outlined"
                    value={configuracion.tiempo}
                    onChange={handleChange}
                    fullWidth
                  />
                </Tooltip>
              </Grid>
            )}
            {EVENTOS_TIPO_MARCADO.includes(configuracion.tipo_alerta._id) && (
              <Grid item xs={12} md={6}>
                <Tooltip
                  title="Indica donde se genera la alerta en relación a las geocercas."
                  placement="top"
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Tipo Marcado</InputLabel>
                    <Select
                      label="Tipo Marcado"
                      name="tipo_marcado" // Clave en el estado
                      value={configuracion.tipo_marcado}
                      onChange={handleChange}
                    >
                      <MenuItem value={0}>General</MenuItem>
                      <MenuItem value={1}>Dentro</MenuItem>
                      <MenuItem value={2}>Fuera</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
              </Grid>
            )}
            {EVENTOS_VECES_VELOCIDAD.includes(
              configuracion.tipo_alerta._id
            ) && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Veces"
                    name="veces"
                    variant="outlined"
                    value={configuracion.veces}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Velocidad (Km/h)"
                    name="velocidad_movimiento"
                    value={configuracion.velocidad_movimiento}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </>
            )}
            {EVENTOS_DISTANCIA.includes(configuracion.tipo_alerta._id) && (
              <Grid item xs={12} md={6}>
                <TextField
                  label="Distancia (Metros)"
                  name="distancia_vehiculos"
                  variant="outlined"
                  value={configuracion.distancia_vehiculos}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            )}

            {/**
             * ✅ Mostrar los campos `veces`, `tiempo` y `tipo marcado`
             * si el `id` no está en ninguno de los grupos EVENTOS_TIEMPO, EVENTOS_TIPO_MARCADO,
             * EVENTOS_VECES_VELOCIDAD o EVENTOS_DISTANCIA.
             */}
            {!(
              EVENTOS_TIEMPO.includes(configuracion.tipo_alerta._id) ||
              EVENTOS_TIPO_MARCADO.includes(configuracion.tipo_alerta._id) ||
              EVENTOS_VECES_VELOCIDAD.includes(configuracion.tipo_alerta._id) ||
              EVENTOS_DISTANCIA.includes(configuracion.tipo_alerta._id)
            ) && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Veces"
                    name="veces"
                    variant="outlined"
                    value={configuracion.veces}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Tiempo (Min.)"
                    name="tiempo"
                    variant="outlined"
                    value={configuracion.tiempo}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Tipo Marcado</InputLabel>
                    <Select
                      label="Tipo Marcado"
                      name="tipo_marcado"
                      value={configuracion.tipo_marcado}
                      onChange={handleChange}
                    >
                      <MenuItem value={0}>General</MenuItem>
                      <MenuItem value={1}>Dentro</MenuItem>
                      <MenuItem value={2}>Fuera</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            </>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          p: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (!errorNombre) { // Verifica si no hay errores antes de cualquier acción
              if (!viewNew) {
                handleOpenSaveDialog(); // Abrir modal de confirmación si viewNew es false
              } else {
                handleGuardarPrincipal(); // Guardar directamente si viewNew es true
              }
            }
          }}
          startIcon={<SaveIcon />}
          disabled={!!errorNombre}
          sx={{ width: "150px", padding: 2 }}
        >
          Guardar
        </Button>
        {/** Botón NUEVO */}
        {!viewNew && ( // ✅ Ajuste: Mostrar el botón si viewNew es false
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCancelDialog} // Abrir modal de cancelar
            startIcon={<CancelIcon />}
            sx={{ width: "150px", padding: 2 }}
          >
            Cancelar
          </Button>
        )}
        {/* // Modal de confirmación para cancelar */}
        <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog}>
          <DialogTitle>Confirmar cancelación</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro de que deseas cancelar esta operación? Los cambios realizados no se guardarán.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancelDialog} color="primary">
              No
            </Button>
            <Button  
               onClick={() => {
                  resetForm(); // Lógica al confirmar la cancelación
                  setCancelDialogOpen(false); // Cerrar modal
                }} color="secondary" autoFocus>
              Sí, cancelar
            </Button>
          </DialogActions>
        </Dialog>
        {/* // Modal para guardar si view es false */}
        <Dialog open={saveDialogOpen} onClose={handleCloseSaveDialog}>
          <DialogTitle>Confirmar guardado</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro de que deseas guardar los cambios realizados?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSaveDialog} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                handleGuardarPrincipal(); // Guardar cambios
                setSaveDialogOpen(false); // Cerrar modal
              }}
              color="secondary"
              autoFocus
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default NuevaAlertaForm;
