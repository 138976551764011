export const getAlertIcon = (alertType) => {
    const alertIcons = {
      2005 : '/alertas/excedevelocidad.png',
      2003 : '/alertas/detenido.png',
      2007 : '/alertas/motor_encendido__.png',
      2008 : '/alertas/motor_apagado__.png',
      3001 : '/alertas/puerta_cerrada.png',
      3000 : '/alertas/puerta_abierta.png',
      2011 : '/alertas/alerta_aproximacion.png',
      2004 : '/alertas/boton_panico_2.png',
      3002 : '/alertas/fuerza_On.png',
      3003 : '/alertas/fuerza_Off.png',
      2001 : '/alertas/entrogeocerca.png',
      2002 : '/alertas/saliogeocerca.png',
      'default': '/alertas/letter_p.png'
    };
    
    return process.env.PUBLIC_URL + (alertIcons[alertType] || alertIcons.default);
  };

  export const getTextEmoji = (textType) => {
    const textEmojis = {
      'unidad': '🚗', //'🚍',
      'chofer': '👤',
      'telefono': '📱',
      'fecha': '📅',
      'mensaje': '💬',
      'direccion': '📍',
      'default': '❗',
      'alerta': '⚠️'
    };
    return textEmojis[textType.toLowerCase()] || textEmojis.default;
  };