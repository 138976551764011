// FilterBar.js
import React, { useState, useEffect,useCallback } from 'react';
import { TextField, IconButton, Paper,  Button, Autocomplete,Typography } from '@mui/material';
 import Grid from '@mui/material/Grid2';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

import { FilterList, Clear } from '@mui/icons-material';
import { vehiculoService, eventoService } from '../../services/apiService';
import { motion } from 'framer-motion';
import { Clear as ClearIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Checkbox } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const FilterBar = ({ onFilter }) => {
  const [vehiculos, setVehiculos] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [selectedVehiculos, setSelectedVehiculos] = useState([]);
  const [selectedEventos, setSelectedEventos] = useState([]);

  const initialHoraInicial = new Date();
  initialHoraInicial.setHours(0, 0, 0, 0);

  const initialHoraFinal = new Date();
  initialHoraFinal.setHours(23, 59, 59, 999);

  const [horaInicial, setHoraInicial] = useState(initialHoraInicial);
  const [horaFinal, setHoraFinal] = useState(initialHoraFinal);

  const resetFilters = () => {
    setSelectedVehiculos([]);
    setSelectedEventos([]);
    setHoraInicial(initialHoraInicial);
    setHoraFinal(initialHoraFinal);
  };

  const CustomListbox = React.forwardRef((props, ref) => {
    const { children, ownerState, ...other } = props; // Excluir ownerState
    return (
      <ul
      {...other}
      ref={ref}
      style={{
        listStyle: 'none',      // Eliminar viñetas
        padding: 0,             // Ajustar padding
        margin: 0,              // Ajustar margin
        maxHeight: 200,         // Mantener altura máxima
        overflow: 'auto',       // Habilitar scroll
      }}
      >
        {children}
      </ul>
    );
  });
  
  const handleSelectAllVehiculos = (checked) => {
    setSelectedVehiculos(checked ? vehiculos : []);
  };
  
  const handleSelectAllEventos = (checked) => {
    setSelectedEventos(checked ? eventos : []);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [vehiculosResult, eventosResult] = await Promise.all([
          vehiculoService.getVehiculos(),
          eventoService.getEventos(),
        ]);
        setVehiculos(vehiculosResult.data || []);
        setEventos(eventosResult.data || []);
      } catch (error) {
        console.error('Error al cargar datos:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    onFilter({
      horaInicial,
      horaFinal,
      vehiculos: selectedVehiculos.map(v => v.id),
      tiposAlerta: selectedEventos.map(e => e.id),
    });
  }, [horaInicial, horaFinal, selectedVehiculos, selectedEventos, onFilter]);

  const handleHoraInicialChange = useCallback((newValue) => {
    setHoraInicial(newValue);
  }, []);

  const handleHoraFinalChange = useCallback((newValue) => {
    setHoraFinal(newValue);
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{ p: 2, mb: 4 }}
      component={motion.div}
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
    >
        <Grid container spacing={2} alignItems="flex-end" columns={{ xs: 12, sm: 12, md: 12 }}>
          <Grid size={{ xs: 12, sm: 6, md: 3 }}>
            <DesktopTimePicker
            label="Hora Inicial"
            value={horaInicial}
            onChange={handleHoraInicialChange}
            views={['hours', 'minutes', 'seconds']}
            ampm={false}
            minutesStep={1}
            secondsStep={1}
            disablePortal
            inputFormat="HH:mm:ss"
            mask="__:__:__"
            slotProps={{
              textField: { 
                variant: 'standard', 
                sx: { width: '100%' }, 
                inputProps: { autoComplete: 'off' } 
              }
            }}
            // renderInput={(params) => (
            //   <TextField
            //     {...params}
            //     fullWidth
            //     variant="standard"
            //     inputProps={{
            //       ...params.inputProps,
            //       autoComplete: 'off',
            //     }}
            //   />
            // )}
          />
        
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3 }}>

        <DesktopTimePicker
        label="Hora Final"
        value={horaFinal}
        onChange={handleHoraFinalChange}
        views={['hours', 'minutes', 'seconds']}
        ampm={false}
        minutesStep={1}
        secondsStep={1}
        disablePortal
        inputFormat="HH:mm:ss"
        mask="__:__:__"
        slotProps={{
          textField: { 
            variant: 'standard', 
            sx: { width: '100%' }, 
            inputProps: { autoComplete: 'off' } 
          }
        }}
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     fullWidth
        //     variant="standard"
        //     inputProps={{
        //       ...params.inputProps,
        //       autoComplete: 'off',
        //     }}
        //   />
        // )}
      />
        
        </Grid>

        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
        <Autocomplete
         sx={{ width: '100%' }}
          multiple
          id="vehiculos-autocomplete"
          options={vehiculos}
          getOptionLabel={(option) => option.alias}
          value={selectedVehiculos}
          onChange={(event, newValue) => setSelectedVehiculos(newValue)}
          // renderTags={(value, getTagProps) => 
          //   value.length > 0 ? `${value.length} seleccionados` : null
          // }
          renderTags={(value, getTagProps) => 
            value.length > 0 ? (
              <Typography 
                key={getTagProps({ index: 0 }).key}  // Asignar 'key' directamente
                variant="body2"
              >
                {`${value.length} seleccionados`}
              </Typography>
            ) : null
          }
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              <Checkbox
                size="small"
                checked={selected}
                sx={{ mr: 1 }}
              />
              {option.alias}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Vehículos"
              placeholder={selectedVehiculos.length === 0 ? "Todos" : ""}
              sx={{ width: '100%' }}
            />
          )}
          slots={{
            listbox: CustomListbox, // Reemplaza ListboxComponent
          }}
          // slotProps={{
          //   listbox: {
          //     style: { maxHeight: 200, overflow: 'auto' }, // Reemplaza ListboxProps
          //   },
          // }}
        />
</Grid>
<Grid size={{ xs: 12, sm: 6, md: 2 }}>
  <Autocomplete
    sx={{ width: '100%' }}
    multiple
    id="eventos-autocomplete"
    options={eventos}
    getOptionLabel={(option) => option.nombre}
    value={selectedEventos}
    onChange={(event, newValue) => setSelectedEventos(newValue)}
    renderTags={(value, getTagProps) => 
      value.length > 0 ? (
        <Typography 
          key={getTagProps({ index: 0 }).key}  // Asignar 'key' directamente
          variant="body2"
        >
          {`${value.length} seleccionados`}
        </Typography>
      ) : null
    }
    renderOption={(props, option, { selected }) => (
      <li {...props} key={option.id}>
        <Checkbox
          size="small"
          checked={selected}
          sx={{ mr: 1 }}
        />
        {option.nombre}
      </li>
    )}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="standard"
        label="Tipos de Alerta"
        placeholder={selectedEventos.length === 0 ? "Todos" : ""}
      />
    )}
    slots={{
      listbox: CustomListbox, // Reemplaza ListboxComponent
    }}
    slotProps={{
      listbox: {
        style: { maxHeight: 200, overflow: 'auto' }, // Reemplaza ListboxProps
      },
    }}
  />
</Grid>

        
<Grid size={{ xs: 12, sm: 6, md: 2 }} sx={{ display: 'flex', justifyContent: 'center' }}>
<Tooltip title="Limpiar filtros">
    <IconButton 
      onClick={resetFilters}
      color="primary"
      size="medium"
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: 'rgba(25, 118, 210, 0.04)',
        },
      }}
    >
        <DeleteIcon />
    </IconButton>
  </Tooltip>
</Grid>


      </Grid>
    </Paper>
  );
};

export default FilterBar;