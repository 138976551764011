// src/components/Chart.js
import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie, Doughnut, Bar } from 'react-chartjs-2';

const generateRandomColor = (existingColors) => {
  let color;
  do {
    color = '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
  } while (existingColors.includes(color));
  return color;
};

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const ChartComponent = ({ type, data, fields,fromPanel,onSegmentClick }) => {

   const existingColors = data.map(item => item.color);

  
  const chartData = {
    labels: data.map((item) => item[fields[0]]),
    datasets: [
      {
        label: fields[1],
        data: data.map((item) => item[fields[1]]),
        backgroundColor: data.map((item) => item.color || generateRandomColor(existingColors)),
      },
    ],
  };
  //console.log('📈 Chart data:', chartData);

  // const chartOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  // };

  // const chartOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       display: !fromPanel, // Hide legend if fromPanel is true
  //     },
  //   },
  // };

  // const chartOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       display: !fromPanel,
  //       onClick: (e, legendItem) => {
  //         const groupKey = legendItem.text;
  //         onSegmentClick(groupKey);
  //       },
  //     },
  //   },
  //   onClick: (evt, elements) => {
  //     if (elements.length > 0) {
  //       const index = elements[0].index;
  //       const groupKey = data[index].groupKey;
  //       onSegmentClick(groupKey);
  //     }
  //   },
  // };


  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: !fromPanel,
        onClick: (e, legendItem) => {
          const groupKey = legendItem.text;
          if (typeof onSegmentClick === 'function') {
            try {
              onSegmentClick(groupKey);
            } catch (error) {
              console.error('Error in legend onClick:', error);
            }
          } else {
            console.warn('onSegmentClick is not defined for legend click');
          }
        },
      },
    },
    onClick: (evt, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const groupKey = data[index].groupKey;
        if (typeof onSegmentClick === 'function') {
          try {
            onSegmentClick(groupKey);
          } catch (error) {
            console.error('Error in chart onClick:', error);
          }
        } else {
          console.warn('onSegmentClick is not defined for chart click');
        }
      }
    },
  };

  return (
    // <div style={{ height: '600px' }}>
    <div style={{ width: '100%', height: '100%' }}>  
      {type === 'PieChart' && <Pie key="pie" data={chartData} options={chartOptions} />}
      {type === 'DoughnutChart' && <Doughnut key="doughnut" data={chartData} options={chartOptions} />}
      {type === 'BarChart' && <Bar key="bar" data={chartData} options={chartOptions} />}
    </div>
  );
};

export default ChartComponent;