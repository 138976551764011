// src/components/LoadingOverlay.js
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Typography, useTheme } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import { useLoading } from '../contexts/LoadingContext';

const LoadingOverlay = () => {
  const { isLoading, loadingMessage } = useLoading();
  const theme = useTheme();

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <motion.div
              animate={{
                rotate: 360,
                scale: [1, 1.2, 1],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <MapIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />
            </motion.div>
            
            <motion.div
              animate={{
                opacity: [0.5, 1, 0.5],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <Typography variant="h6" sx={{ mt: 2, color: theme.palette.primary.main }}>
                {loadingMessage || 'Cargando...'}
              </Typography>
            </motion.div>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoadingOverlay;