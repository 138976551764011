// src/components/Table.js
import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Paper, Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,Typography
 } from '@mui/material';
import { motion } from 'framer-motion';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// Function to get nested or flat values from an object
const getNestedValue = (obj, path) => {
  if (obj.hasOwnProperty(path)) {
    return obj[path];
  }
  const value = path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), obj);
  return value;
};

export const Table = ({ data, fields, grouping,onConvertView }) => {
  const { groupBy, showCount, showPercentage, showNested } = grouping || {};
  const [expandedGroups, setExpandedGroups] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  console.log('📊 Table:', data, fields, grouping);
  console.log('📊 Grouping:', groupBy, showCount, showPercentage, showNested);

  let groupedData = {};
  if (groupBy) {
    data.forEach(item => {
      const groupKey = getNestedValue(item, groupBy) || 'N/A';
      if (!groupedData[groupKey]) {
        groupedData[groupKey] = [];
      }
      groupedData[groupKey].push(item);
    });
  } else {
    groupedData['ungrouped'] = data;
  }

  const GroupCellRenderer = React.forwardRef((props, ref) => {
    const { data, value } = props;
    if (!data.isGroup) return null;

    let icon;
    if (showNested && data.expanded) {
      icon = <ExpandLessIcon />;
    } else if (showNested) {
      icon = <ExpandMoreIcon />;
    } else {
      icon = null;
    }

    let groupLabel = value;
    if (showCount) {
      groupLabel += ` (${data.count})`;
    }
    if (showPercentage) {
      groupLabel += ` (${data.percentage.toFixed(1)}%)`;
    }

    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
    const handleConvert = (viewType) => {
      onConvertView(viewType);
      handleMenuClose();
    };

    
    return (
      <div
        ref={ref}
        style={{ display: 'flex', alignItems: 'center', cursor: showNested ? 'pointer' : 'default' }}
        onClick={() => {
          if (showNested) {
            setExpandedGroups(prev => ({
              ...prev,
              [data.groupKey]: !prev[data.groupKey],
            }));
          }
        }}
      >
        {icon}
        <span style={{ marginLeft: '5px' }}>{groupLabel}</span>
      </div>
    );
  });

  const totalItems = data.length;
  const rowData = useMemo(() => {
    if (!groupBy) {
      return data.map((item, index) => ({
        id: String(index),
        ...fields.reduce((acc, field) => ({
          ...acc,
          [field]: getNestedValue(item, field),
        }), {}),
      }));
    } else {
      return Object.entries(groupedData).flatMap(([groupKey, items]) => {
        const count = items.length;
        const percentage = (count / totalItems) * 100;
        const groupRow = {
          id: `group-${groupKey}`,
          groupKey: groupKey,
          isGroup: true,
          count: count,
          percentage: percentage,
          expanded: expandedGroups[groupKey] || false,
        };
        if (showNested && expandedGroups[groupKey]) {
          return [
            groupRow,
            ...items.map((item, index) => ({
              id: `item-${groupKey}-${index}`,
              ...fields.reduce((acc, field) => ({
                ...acc,
                [field]: getNestedValue(item, field),
              }), {}),
            })),
          ];
        } else {
          return [groupRow];
        }
      });
    }
  }, [groupedData, expandedGroups, fields, groupBy, showNested, totalItems, showCount, showPercentage]);

  const columnDefs = useMemo(() => {
    let columns = fields.map(field => ({
      headerName: field.split('.').pop(),
      field: field,
      valueGetter: params => params.data[field],
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 150,
    }));

    if (groupBy) {
      columns.unshift({
        headerName: '',
        field: 'groupKey',
        cellRenderer: GroupCellRenderer,
        cellClass: params => params.data.isGroup ? 'group-row' : '',
      });
    }

    return columns;
  }, [fields, groupBy, showCount, showPercentage, showNested]);

  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true,
    filter: true,
  }), []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleConvert = (viewType) => {
    onConvertView(viewType);
    handleMenuClose();
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
   <Box sx={{ height: 600, width: '100%', p: 2 }}>

      {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Table</Typography>
        {grouping.groupBy && (
          <>
            <Tooltip title="Convert View">
              <IconButton onClick={handleMenuClick}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleConvert('Panel')}>Panel</MenuItem>
              <MenuItem onClick={() => handleConvert('BarChart')}>Bar Chart</MenuItem>
              <MenuItem onClick={() => handleConvert('PieChart')}>Pie Chart</MenuItem>
              <MenuItem onClick={() => handleConvert('DoughnutChart')}>Doughnut Chart</MenuItem>
            </Menu>
          </>
        )}
      </Box> */}

        <Paper elevation={3} className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            groupDisplayType="multipleColumns"
            suppressAggFuncInHeader={true}
            getRowId={params => String(params.data.id)}
            groupDefaultExpanded={showNested && groupBy ? -1 : 1}
            rowSelection={{ type: 'multiple' }}          
            onGridReady={params => params.api.sizeColumnsToFit()}
          />
        </Paper>
       </Box> 
    </motion.div>
  );
};

export default Table;