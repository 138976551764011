// src/components/PanelGroupWithChart.js
import React, { useMemo, useState, useEffect,useRef,useCallback } from 'react';
import { 
  Paper, 
  Typography, 
  Box, 
  Grid,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Popover,InputLabel,Tooltip
} from '@mui/material';
import { BarChart as ChartIcon } from '@mui/icons-material';
import { HexColorPicker } from 'react-colorful';
import { motion } from 'framer-motion';
import ChartComponent from './Chart';
import Table from './Table';
import DetailTable from './DetailTable';
import {
  BarChart as BarChartIcon,
  PieChart as PieChartIcon,
  DonutLarge as DonutIcon,
  TableChart as TableIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';

const generateUniqueColors = (num) => {
  const colors = [];
  const saturation = 70; // porcentaje
  const lightness = 50; // porcentaje

  for (let i = 0; i < num; i++) {
    const hue = Math.floor((360 / num) * i);
    colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
  }

  return colors;
};

// Función para obtener valores anidados
const getNestedValue = (obj, path) => {
  if (obj.hasOwnProperty(path)) {
    return obj[path];
  }
  const value = path
    .split('.')
    .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), obj);

    //console.log(`Obteniendo valor para path "${path}":`, value);
  return value;
};

const PanelGroupWithChart = ({ 
  groupedData, 
  groupBy,
  //panelSettings,
  //updatePanelSettings,
  setGroupedData,
  fields, 
}) => {
  //const { showChart, chartType } = panelSettings;
  const [localColoredData, setLocalColoredData] = useState(groupedData);
  const chartRef = useRef(null);
  const [hiddenGroups, setHiddenGroups] = useState(new Set());
  const [globalGroupBy, setGlobalGroupBy] = useState('');
  const [detailGroupBy, setDetailGroupBy] = useState(groupBy);
  const [tableViews, setTableViews] = useState({}); 
  const detailTableRef = useRef({});
  const [tableGroupings, setTableGroupings] = useState({});
  const [showChart, setShowChart] = useState(true);
  const [chartType, setChartType] = useState('DoughnutChart');

  const originalData = useMemo(() => {
    return groupedData.flatMap(group => group.items);
  }, [groupedData]);

const groupData = (data, groupByField) => {
    const grouped = data.reduce((acc, item) => {
        const key = getNestedValue(item, groupByField);
        const groupKey = key !== null && key !== undefined ? key : 'Otros';
        if (!acc[groupKey]) {
            acc[groupKey] = { items: [], count: 0 };
        }
        acc[groupKey].items.push(item);
        acc[groupKey].count += 1;
        return acc;
    }, {});

    // Calcular el total para los porcentajes
    const totalCount = Object.values(grouped).reduce((sum, g) => sum + g.count, 0);
    //console.log(`Total Count: ${totalCount}`);

    return Object.entries(grouped).map(([key, value]) => {
        const percentage = totalCount > 0 ? (value.count / totalCount) * 100 : 0;
        //console.log(`Group: ${key}, Count: ${value.count}, Percentage: ${percentage.toFixed(2)}%`);
        return {
            groupKey: key,
            items: value.items,
            count: value.count,
            percentage: percentage
        };
    });
};

  const recomputedGroupedData = useMemo(() => {
    if (!detailGroupBy) {
      return groupedData;
    }
    return groupData(originalData, detailGroupBy);
  }, [originalData, detailGroupBy, groupedData]); 


  useEffect(() => {
  //console.log('groupedData:', groupedData);
  //console.log('originalData:', originalData);
}, [groupedData, originalData]);


    const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  };

  // Función simplificada para mostrar/ocultar gráfico
  const toggleShowChart = () => {
    setShowChart(!showChart);
  };


  const toggleGroupVisibility = (groupKey) => {
    setHiddenGroups(prev => {
      const newHidden = new Set(prev);
      if (newHidden.has(groupKey)) {
        newHidden.delete(groupKey);
      } else {
        newHidden.add(groupKey);
      }
      return newHidden;
    });
  };

//   const total = groupedData.reduce((acc, curr) => {
//     acc.total += curr.count;
//     acc.percentage += curr.percentage;
//     return acc;
//   }, { total: 0, percentage: 0 });


const total = groupedData.reduce((acc, curr) => {
  acc.total += curr.count;
  acc.percentage += curr.percentage;
  return acc;
}, { total: 0, percentage: 0 });
// 
// const total = groupedData.reduce((acc, curr) => {
//     //console.log('Accumulator:', acc);
//     //console.log('Current item:', curr);
//     return {
//         total: acc.total + (curr.count || 0),
//         percentage: acc.percentage + (curr.percentage || 0)
//     };
// }, { total: 0, percentage: 0 });

  const totalRecords = useMemo(() => {
    return recomputedGroupedData.reduce((sum, group) => sum + group.items.length, 0);
  }, [recomputedGroupedData]); 

  useEffect(() => {
    if (showChart) {
      //console.log('Chart is shown, generating colors for grouped data.');
      const colors = generateUniqueColors(groupedData.length);
      const newColoredData = groupedData.map((group, index) => {
        const color = group.color || colors[index];
        //console.log(`Group ${group.groupKey} assigned color: ${color}`);
        return {
          ...group,
          color,
        };
      });
      setLocalColoredData(newColoredData);
      //console.log('New colored data set:', newColoredData);
     
      // setGroupedData(newColoredData);
    } else {
      //console.log('Chart is hidden, using original grouped data.');
      setLocalColoredData(groupedData);
    }
  }, [groupedData, showChart]);
  

 
  // Estado para el selector de colores
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleColorClick = (event, group) => {
    setAnchorEl(event.currentTarget);
    setSelectedGroup(group);
  };

  const handleColorChange = (newColor) => {
    if (selectedGroup) {
      const updatedData = localColoredData.map(group => 
        group.groupKey === selectedGroup.groupKey
          ? { ...group, color: newColor }
          : group
      );

      // Actualizar solo el color en el dataset del gráfico
      if (chartRef.current) {
        const datasetIndex = 0; // Asumiendo que solo tenemos un dataset
        const itemIndex = localColoredData.findIndex(
          item => item.groupKey === selectedGroup.groupKey
        );
        
        chartRef.current.data.datasets[datasetIndex].backgroundColor[itemIndex] = newColor;
        chartRef.current.update('none'); // Actualizar sin animación
      }
      setGroupedData(updatedData);
      setLocalColoredData(updatedData);
    }
  };


  const handleClose = () => {
    setAnchorEl(null);
    setSelectedGroup(null);
  };

//   useEffect(() => {
//     updatePanelSettings({ ...panelSettings, fromPanel: true });
//   }, []);

  const visibleData = localColoredData.filter(
    group => !hiddenGroups.has(group.groupKey)
  );

  const GroupingSelector = () => (
    <Box sx={{ mb: 4 }}>
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel>Agrupar todos por</InputLabel>
        <Select
          value={globalGroupBy}
          onChange={(e) => setGlobalGroupBy(e.target.value)}
          label="Agrupar todos por"
        >
          <MenuItem value="">Ninguno</MenuItem>
          {fields.map((field) => (
            <MenuItem key={field} value={field}>
              {field}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );

const handleViewChange = (groupKey, newViewType) => {
  setTableViews(prev => ({
    ...prev,
    [groupKey]: newViewType
  }));
};

  const open = Boolean(anchorEl);
  const id = open ? 'color-popover' : undefined;

  return (
    <div> 
    <Paper 
      elevation={0} 
      sx={{ 
        display: 'flex', 
        flexDirection: 'row', 
        height: '400px',//'100%',
        width: '100%',
        overflow: 'hidden',
        backgroundColor: 'background.paper'
      }}
    >
      <Box sx={{ 
        width: showChart ? '50%' : '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%', // Take full height
        // width: showChart ? '50%' : '100%',
        // maxHeight: '100%',
        // overflowY: 'auto',
        // p: 2,
        // boxSizing: 'border-box'
      }}>
       

<Box sx={{ 
          p: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Typography variant="h6">
              {/* {groupBy} Summary */}
              Alertas
            </Typography>

            {process.env.REACT_APP_SUPERUSERACTIVATE === 'true' && (
          <>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {showChart && (
                <FormControl size="small">
                  <Select
                    value={chartType}
                    onChange={handleChartTypeChange}
                    sx={{ minWidth: 120 }}
                  >
                    <MenuItem value="BarChart">Bar Chart</MenuItem>
                    <MenuItem value="PieChart">Pie Chart</MenuItem>
                    <MenuItem value="DoughnutChart">Doughnut Chart</MenuItem>
                  </Select>
                </FormControl>
              )}
              <IconButton 
                onClick={toggleShowChart}
                color={showChart ? "primary" : "default"}
              >
                <ChartIcon />
              </IconButton>
            </Box>
            </>
          )}


          </Box>
        </Box>


        <Box sx={{ 
          flex: 1,
          overflowY: 'auto',
          p: 2
        }}>

        {/* <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}> */}
          {/* {coloredData.map((group) => ( */}
          {localColoredData.map((group) => (
            <Box
              key={group.groupKey}
              sx={{
                p: 1,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {showChart && (
                <IconButton 
                  onClick={(e) => handleColorClick(e, group)}
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    backgroundColor: group.color,
                    marginRight: 1,
                    border: '1px solid #ccc',
                    padding: 0,
                    '&:hover': {
                      border: '1px solid #000',
                    }
                  }}
                />
              )}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                <Typography 
                  variant="subtitle1" 
                  fontWeight="medium"
                  onClick={() => toggleGroupVisibility(group.groupKey)}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: hiddenGroups.has(group.groupKey) ? 'line-through' : 'none',
                    '&:hover': {
                      opacity: 0.8
                    }
                  }}
                >
                  {group.groupKey}
                </Typography>

                  {/* <Typography variant="subtitle1" fontWeight="medium">
                    {group.groupKey}
                  </Typography> */}
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    Count: {group.count}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                     {/* {(group.percentage || 0).toFixed(1)}% */}
                     {((group.count / total.total) * 100).toFixed(1)}%

                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}

          {/* <Paper 
            elevation={1}
            sx={{
              p: 2,
              mt: 2,
              bgcolor: 'primary.light',
              color: 'primary.contrastText',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography fontWeight="bold">Total</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography fontWeight="bold">{total.total}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography fontWeight="bold">
                {total.percentage.toFixed(1)}%
                </Typography>
              </Grid>
            </Grid>
          </Paper> */}
        {/* </Box> */}

        </Box>

        <Box sx={{ p: 2 }}>
              <Paper 
                elevation={1}
                sx={{
                  p: 2,
                  bgcolor: 'primary.light',
                  color: 'primary.contrastText',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography fontWeight="bold">Total</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography fontWeight="bold">{total.total}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography fontWeight="bold">
                      {/* {total.percentage.toFixed(1)}% */}
                      100%
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Box>

      </Box>

      {showChart && (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    style={{ flex: 1, height: '100%' }}
  >
    <Box sx={{ 
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      backgroundColor: 'background.paper',     
    }}>
      <ChartComponent
        type={chartType}
        data={visibleData}
        fields={['groupKey', 'count', 'color']}
        fromPanel={true} 
      />
    </Box>
  </motion.div>
)}    

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {selectedGroup && (
          <Box sx={{ padding: 2 }}>
            <HexColorPicker
              color={selectedGroup.color}
              onChange={(color) => handleColorChange(color)}
            />
          </Box>
        )}
      </Popover>    

    </Paper>      
   </div>
  );
};

export default PanelGroupWithChart;