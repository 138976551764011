import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, Button, Box, Typography, Grid ,Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

const VehicleForm = ({ onSaveVehicle, initialValues }) => {
  const [vehicleData, setVehicleData] = useState({
    IMEI: "",
    Alias: "",
    Modelo: "",
    Marca: "",
    Placas: "",
    No_Economico: "",
  });
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false); // Nuevo estado para el modal de Guardar

  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false); // Cierra el modal
  };

  const handleCloseSaveDialog = () => {
    setSaveDialogOpen(false); // Cierra el modal de guardar
  };

  // Actualiza los valores iniciales al cambiar `initialValues`
  useEffect(() => {
    if (initialValues) {
      setVehicleData(initialValues);
    }
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVehicleData({
      ...vehicleData,
      [name]: value,
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   onSaveVehicle(vehicleData);
  //   setVehicleData({
  //     IMEI: "",
  //     Alias: "",
  //     Modelo: "",
  //     Marca: "",
  //     Placas: "",
  //     No_Economico: "",
  //   });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaveDialogOpen(true); // Abre el modal de confirmación antes de guardar
  };

  const confirmSave = () => {
    onSaveVehicle(vehicleData); // Lógica actual de guardar
    setVehicleData({
      IMEI: "",
      Alias: "",
      Modelo: "",
      Marca: "",
      Placas: "",
      No_Economico: "",
    }); // Resetea los datos después de guardar
    setSaveDialogOpen(false); // Cierra el modal de guardar
  };

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 2,
        boxShadow: 1,
        backgroundColor: "white",
        mt: 3,
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        {/* {initialValues ? 'Editar Vehículo' : 'Agregar Vehículo'} */}
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              label="IMEI"
              name="IMEI"
              value={vehicleData.IMEI}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Alias"
              name="Alias"
              value={vehicleData.Alias}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Modelo"
              name="Modelo"
              value={vehicleData.Modelo}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Marca"
              name="Marca"
              value={vehicleData.Marca}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Placas"
              name="Placas"
              value={vehicleData.Placas}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="No Económico"
              name="No_Economico"
              value={vehicleData.No_Economico}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}
        >
          <Button
            startIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            type="submit"
          >
            Guardar
          </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CancelIcon />}
              onClick={() => setCancelDialogOpen(true)} // Abrir el modal
            >
              Cancelar
            </Button>
          </Box>
          {/* Modal de confirmación para cancelar */}
      <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog}>
        <DialogTitle>Confirmar cancelación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas cancelar esta operación? Los datos del vehículo se restablecerán a los valores iniciales.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelDialog} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              setVehicleData({
                IMEI: "",
                Alias: "",
                Modelo: "",
                Marca: "",
                Placas: "",
                No_Economico: "",
              }); // Lógica para resetear los datos
              setCancelDialogOpen(false); // Cerrar modal
            }}
            color="secondary"
            autoFocus
          >
            Sí, cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Modal de confirmación para guardar */}
        <Dialog open={saveDialogOpen} onClose={handleCloseSaveDialog}>
          <DialogTitle>Confirmar Guardar</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro de que deseas guardar estos datos del vehículo?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSaveDialog} color="primary">
              No
            </Button>
            <Button onClick={confirmSave} color="secondary" autoFocus>
              Sí, guardar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

VehicleForm.propTypes = {
  onSaveVehicle: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default VehicleForm;
