import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import emojiFlags from "emoji-flags";
import { confirmDialog } from "../../../../components/ConfirmDialog";

const countries = [
  { code: "AR", name: "Argentina", dialCode: "54" },
  { code: "BR", name: "Brazil", dialCode: "55" },
  { code: "CO", name: "Colombia", dialCode: "57" },
  { code: "CR", name: "Costa Rica", dialCode: "506" },
  { code: "CL", name: "Chile", dialCode: "56" },
  { code: "EC", name: "Ecuador", dialCode: "593" },
  { code: "MX", name: "Mexico", dialCode: "52" },
];

const initFormState = {
  id: null,
  nombreContacto: "",
  lada: "",
  telefono: "",
  correo: "",
  notificaciones: {
    telefono: false,
    correo: false,
    whatsapp: false,
  },
};

export default function AgregarContactoModal({ open, onClose, crearContacto }) {
  const [lada, setLada] = useState("52");
  const [formData, setFormData] = useState(initFormState);
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};

    // Validar Nombre
    if (!formData.nombreContacto.trim()) {
      newErrors.nombreContacto = "El nombre es obligatorio";
    } else if (formData.nombreContacto.length > 50) {
      newErrors.nombreContacto = "El nombre no debe exceder 50 caracteres";
    }

    // Validar Teléfono
    if (!formData.telefono.trim()) {
      newErrors.telefono = "El número de teléfono es obligatorio";
    } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formData.telefono)) {
      newErrors.telefono =
        "El teléfono debe tener el formato (999) 999-9999 y 10 dígitos";
    }

    // Validar Correo
    if (!formData.correo.trim()) {
      newErrors.correo = "El correo es obligatorio";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.correo)) {
      newErrors.correo = "El correo no tiene un formato válido";
    } else if (formData.correo.length > 100) {
      newErrors.correo = "El correo no debe exceder 100 caracteres";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      notificaciones: {
        ...prev.notificaciones,
        [name]: checked,
      },
    }));
  };

  const handleLadaChange = (event) => {
    setLada(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "nombreContacto" && value.length > 50) return; // Limita Nombre
    if (name === "correo" && value.length > 100) return; // Limita Correo

    if (name === "telefono") {
      const phone = value.replace(/\D/g, "");
      if (phone.length > 10) return;
      const formattedPhone = phone
        .replace(/^(\d{3})(\d{3})(\d{0,4})$/, "($1) $2-$3")
        .trim();
      setFormData({ ...formData, [name]: formattedPhone });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleGuardar = async () => {
    if (!validate()) return;
    // Limpia el formato del número de teléfono
    const clearPhone = formData.telefono.replace(/\D/g, ""); // Elimina todo excepto números

    const newContacto = {
      nombreContacto: formData.nombreContacto,
      sms: [lada, "1", clearPhone],
      notificacionSms: formData.notificaciones.telefono,
      whatsapp: [lada, "1", clearPhone],
      notificacionWhatsapp: formData.notificaciones.whatsapp,
      correo: formData.correo,
      notificacionCorreo: formData.notificaciones.correo,
      telegram: "",
      notificacionTelegram: false,
      endpoint: "http://localhost:5241/api/services/Contacto/agregar",
      websocket: "ws://localhost:5241/api/services/Contacto/agregar",
      voz: [lada, clearPhone],
      status: 1,
    };
    await crearContacto(newContacto);
    // Restablece el formulario al estado inicial
    setFormData(initFormState);
    setLada("52");
    onClose();
  };

  const handleClose = async () => {
    const isConfirmed = await confirmDialog({
      title: "Confirmar cancelación.",
      message:
        "¿Estas seguro de que deseas cancelar la operación? Los cambios realizados no se guardarán.",
      confirmLabel: "Sí, cancelar",
      cancelLabel: "No",
    });
    if (!isConfirmed) return;
    setFormData(initFormState);
    setLada("52");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Agregar Contacto</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} marginTop={2}>
          {/* Nombre, Lada, Teléfono y Correo */}

          <Grid item xs={12} md={6}>
            <TextField
              label="Nombre"
              name="nombreContacto"
              value={formData.nombreContacto}
              onChange={handleChange}
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.nombreContacto}
              helperText={errors.nombreContacto}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Lada</InputLabel>
              <Select
                label="Lada"
                value={lada}
                onChange={handleLadaChange}
                renderValue={(selected) => {
                  const country = countries.find(
                    (c) => c.dialCode === selected
                  );
                  return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: 8 }}>
                        {emojiFlags.countryCode(country.code).emoji}
                      </span>
                      <span>{`${country.name} +${country.dialCode}`}</span>
                    </Box>
                  );
                }}
              >
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.dialCode}>
                    <ListItemIcon>
                      {emojiFlags.countryCode(country.code).emoji}
                    </ListItemIcon>
                    <ListItemText
                      primary={`${country.name} +${country.dialCode}`}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Teléfono"
              name="telefono"
              value={formData.telefono}
              onChange={handleChange}
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.telefono}
              helperText={errors.telefono}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Correo"
              name="correo"
              value={formData.correo}
              onChange={handleChange}
              variant="outlined"
              size="small"
              fullWidth
              error={!!errors.correo}
              helperText={errors.correo}
            />
          </Grid>

          {/* Checkboxes */}
          <Grid item xs={12}>
            <Box display="flex" gap={3}>
              <FormControlLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  marginLeft: 0,
                }}
                control={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <PhoneIcon />
                    <Checkbox
                      checked={formData.notificaciones.telefono}
                      onChange={handleCheckboxChange}
                      name="telefono"
                    />
                  </Box>
                }
              />
              <FormControlLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  marginLeft: 0,
                }}
                control={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <EmailIcon />
                    <Checkbox
                      checked={formData.notificaciones.correo}
                      onChange={handleCheckboxChange}
                      name="correo"
                    />
                  </Box>
                }
              />
              <FormControlLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  marginLeft: 0,
                }}
                control={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <WhatsAppIcon />
                    <Checkbox
                      checked={formData.notificaciones.whatsapp}
                      onChange={handleCheckboxChange}
                      name="whatsapp"
                    />
                  </Box>
                }
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleGuardar}
        >
          Guardar
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CancelIcon />}
          onClick={handleClose}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
