// src/config/apiConfig.js
export const API_CONFIG = {
    BASE_URL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:5241/api',
    X_API_KEY: process.env.REACT_APP_X_API_KEY || '6735307e6c2c7a7e4828de01',
    TIMEOUT: 10000,
    HEADERS: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };
