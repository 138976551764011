// src/pages/report_v2/configurationService.js
import defaultConfigs from './defaultReportConfigs.json';

export const configurationService = {
  // loadConfigs: async () => {
  //   try {
  //     console.log('🔄 Cargando configuraciones guardadas...');
  //     const configs = localStorage.getItem('reportConfigs');
  //     const parsedConfigs = configs ? JSON.parse(configs) : [];
      
  //     // Normalizar estructura de las configuraciones
  //     const normalizedConfigs = parsedConfigs.map(config => ({
  //       id: config.id,
  //       name: config.name,
  //       dataSource: config.dataSource,
  //       selectedFields: config.selectedFields || [],
  //       filters: config.filters || {},
  //       grouping: {
  //         groupBy: config.grouping?.groupBy || '',
  //         showCount: config.grouping?.showCount ?? true,
  //         showPercentage: config.grouping?.showPercentage ?? false,
  //         showNested: config.grouping?.showNested ?? true,
  //         chartSettings: {
  //           showChart: config.grouping?.chartSettings?.showChart ?? false,
  //           chartType: config.grouping?.chartSettings?.chartType || 'BarChart'
  //         }
  //       },
  //       visualization: {
  //         type: config.visualization?.type || 'Table',
  //         chartType: config.visualization?.chartType,
  //         showChart: config.visualization?.showChart
  //       },
  //       createdAt: config.createdAt,
  //       updatedAt: config.updatedAt
  //     }));

  //     console.log('📦 Configuraciones normalizadas:', normalizedConfigs);
  //     return normalizedConfigs;
  //   } catch (error) {
  //     console.error('❌ Error al cargar configuraciones:', error);
  //     throw error;
  //   }
  // },
  // saveConfig: async (config) => {
  //   try {
  //     console.log('🔄 Guardando nueva configuración...');
  //     const configs = await configurationService.loadConfigs();

  //     const groupingConfig = {
  //       groupBy: config.grouping?.groupBy || '',
  //       showCount: config.grouping?.showCount ?? true,
  //       showPercentage: config.grouping?.showPercentage ?? false,
  //       showNested: config.grouping?.showNested ?? true,
  //       chartSettings: {
  //         showChart: config.grouping?.chartSettings?.showChart ?? false,
  //         chartType: config.grouping?.chartSettings?.chartType || 'BarChart'
  //       }
  //     };

  //     console.log('📊 Estructura de agrupación normalizada:', groupingConfig);


      
  //     // Normalizar nueva configuración
  //     const newConfig = {
  //       ...config,
  //       id: crypto.randomUUID(),
  //       createdAt: new Date().toISOString(),
  //       updatedAt: new Date().toISOString(),
  //       grouping: groupingConfig,
  //       visualization: {
  //         type: config.visualization?.type || 'Table',
  //         chartType: config.visualization?.chartType,
  //         showChart: config.visualization?.showChart
  //       }
  //     };
      
  //     configs.push(newConfig);
  //     localStorage.setItem('reportConfigs', JSON.stringify(configs));
      
  //     console.log('✅ Nueva configuración guardada:', {
  //       id: newConfig.id,
  //       name: newConfig.name,
  //       type: newConfig.visualization.type
  //     });
      
  //     return newConfig;
  //   } catch (error) {
  //     console.error('❌ Error al guardar configuración:', error);
  //     throw error;
  //   }
  // },

  loadConfigs: async () => {
    try {
      console.log('🔄 Cargando configuraciones guardadas...');
      const configs = localStorage.getItem('reportConfigs');
      console.log('📦 Config', configs);
      const parsedConfigs = configs ? JSON.parse(configs) : [];

      const allConfigs = [...defaultConfigs, ...parsedConfigs];


      const uniqueConfigs = allConfigs.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          // Si existe, mantener la versión más reciente
          return acc.map(item => 
            item.id === current.id 
              ? new Date(item.updatedAt) > new Date(current.updatedAt) 
                ? item 
                : current
              : item
          );
        }
        return acc;
      }, []);


      // Normalizar estructura de las configuraciones
      //const normalizedConfigs = parsedConfigs.map(config => ({
        const normalizedConfigs = uniqueConfigs.map(config => ({

        id: config.id,
        name: config.name,
        dataSource: config.dataSource,
        selectedFields: config.selectedFields || [],
        filters: config.filters || {},
        grouping: {
          groupBy: config.grouping?.groupBy || '',
          showCount: config.grouping?.showCount ?? true,
          showPercentage: config.grouping?.showPercentage ?? false,
          showNested: config.grouping?.showNested ?? true,
          colors: config.grouping?.colors || {}, // Añadido para colores
        },
        visualization: {
          type: config.visualization?.type || 'Table',
          chartType: config.visualization?.chartType,
          showChart: config.visualization?.showChart
        },
        createdAt: config.createdAt,
        updatedAt: config.updatedAt
      }));

      console.log('📦 Configuraciones normalizadas:', normalizedConfigs);
      return normalizedConfigs;
    } catch (error) {
      console.error('❌ Error al cargar configuraciones:', error);
      throw error;
    }
  },
  saveConfig: async (config) => {
    try {
     // console.log('🔄 Guardando nueva configuración...');
      const configs = await configurationService.loadConfigs();

      const groupingConfig = {
        groupBy: config.grouping?.groupBy || '',
        showCount: config.grouping?.showCount ?? true,
        showPercentage: config.grouping?.showPercentage ?? false,
        showNested: config.grouping?.showNested ?? true,
        colors: config.grouping?.colors || {}, // Añadido para colores
      };

      console.log('📊 Estructura de agrupación normalizada:', groupingConfig);

      // Normalizar nueva configuración
      const newConfig = {
        ...config,
        id: crypto.randomUUID(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        grouping: groupingConfig,
        visualization: {
          type: config.visualization?.type || 'Table',
          chartType: config.visualization?.chartType,
          showChart: config.visualization?.showChart
        }
      };
      
      configs.push(newConfig);
      localStorage.setItem('reportConfigs', JSON.stringify(configs));
      
      console.log('✅ Nueva configuración guardada:', {
        id: newConfig.id,
        name: newConfig.name,
        type: newConfig.visualization.type
      });
      
      return newConfig;
    } catch (error) {
      console.error('❌ Error al guardar configuración:', error);
      throw error;
    }
  },
  updateConfig: async (id, config) => {
    try {
      console.log('🔄 Actualizando configuración:', id);
      const configs = await configurationService.loadConfigs();
      const index = configs.findIndex(c => c.id === id);
      
      if (index !== -1) {
        const groupingConfig = {
          groupBy: config.grouping?.groupBy || '',
          showCount: config.grouping?.showCount ?? true,
          showPercentage: config.grouping?.showPercentage ?? false,
          showNested: config.grouping?.showNested ?? true,
          colors: config.grouping?.colors || {}, // Añadido para colores
        };

        configs[index] = {
          ...config,
          id,
          updatedAt: new Date().toISOString(),
          grouping: groupingConfig,
          visualization: {
            type: config.visualization?.type || 'Table',
            chartType: config.visualization?.chartType,
            showChart: config.visualization?.showChart
          }
        };
        localStorage.setItem('reportConfigs', JSON.stringify(configs));
        console.log('✅ Configuración actualizada:', id);
        return configs[index];
      }
      
      throw new Error('Configuración no encontrada');
    } catch (error) {
      console.error('❌ Error al actualizar configuración:', error);
      throw error;
    }
  },
    // updateConfig: async (id, config) => {
    //   try {
    //     console.log('🔄 Actualizando configuración:', id);
    //     const configs = await configurationService.loadConfigs();
    //     const index = configs.findIndex(c => c.id === id);
        
    //     if (index !== -1) {
    //       configs[index] = {
    //         ...config,
    //         id,
    //         updatedAt: new Date().toISOString(),
    //       };
    //       localStorage.setItem('reportConfigs', JSON.stringify(configs));
    //       console.log('✅ Configuración actualizada:', id);
    //       return configs[index];
    //     }
        
    //     throw new Error('Configuración no encontrada');
    //   } catch (error) {
    //     console.error('❌ Error al actualizar configuración:', error);
    //     throw error;
    //   }
    // },
  

    // loadConfigs: async () => {
    //   try {
    //     const configs = localStorage.getItem('reportConfigs');
    //     return configs ? JSON.parse(configs) : [];
    //   } catch (error) {
    //     console.error('Error loading configs:', error);
    //     throw error;
    //   }
    // },
  
    // saveConfig: async (config) => {
    //   try {
    //     const configs = await configurationService.loadConfigs();
    //     const newConfig = {
    //       ...config,
    //       id: crypto.randomUUID(),
    //       createdAt: new Date().toISOString(),
    //       updatedAt: new Date().toISOString(),
    //     };
    //     configs.push(newConfig);
    //     localStorage.setItem('reportConfigs', JSON.stringify(configs));
    //     return newConfig;
    //   } catch (error) {
    //     console.error('Error saving config:', error);
    //     throw error;
    //   }
    // },
  
    // updateConfig: async (id, config) => {
    //   try {
    //     const configs = await configurationService.loadConfigs();
    //     const index = configs.findIndex(c => c.id === id);
    //     if (index !== -1) {
    //       configs[index] = {
    //         ...config,
    //         id,
    //         updatedAt: new Date().toISOString(),
    //       };
    //       localStorage.setItem('reportConfigs', JSON.stringify(configs));
    //       return configs[index];
    //     }
    //     throw new Error('Config not found');
    //   } catch (error) {
    //     console.error('Error updating config:', error);
    //     throw error;
    //   }
    // },
  
    deleteConfig: async (id) => {
      try {
        const configs = await configurationService.loadConfigs();
        const newConfigs = configs.filter(c => c.id !== id);
        localStorage.setItem('reportConfigs', JSON.stringify(newConfigs));
      } catch (error) {
        console.error('Error deleting config:', error);
        throw error;
      }
    }
  };