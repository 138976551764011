// utils/popupUtils.js
import { getAlertIcon } from './alertUtils';
import WarningIcon from '../assets/warning.svg';
import LocationIcon from '../assets/location.svg';

export const getStatusColor = (estado) => {
  switch (estado.toLowerCase()) {
    case 'rojo': return '#ff4444';
    case 'amarillo': return '#ff9800';
    default: return '#4CAF50';
  }
};

const removeEmojis = (text) => {
  return text.replace(/[\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF][\uDC00-\uDFFF]|[\uD83D][\uDC00-\uDFFF]/g, '');
};

const formatPhoneNumber = (phoneNumber) => {
  // Remover cualquier caracter que no sea número
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  
  // Verificar si tiene 10 dígitos
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0,3)})${cleaned.slice(3,6)}-${cleaned.slice(6)}`;
  }
  
  // Si no tiene 10 dígitos, retornar el original o 'No disponible'
  return phoneNumber || 'No disponible';
};

// utils/popupUtils.js
export const createPopupContent = (alert) => {
  return `
    <style>
      .popup-card {
        background: white;
        border-left: 4px solid ${getStatusColor(alert.estado)};
        border-top: 4px solid ${getStatusColor(alert.estado)};
        min-width: 460px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        border-radius: 4px;
      }
      .main-content {
        display: flex;
        padding: 16px;
      }
      .icon-container {
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .alert-icon3 {
        width: 48px;
        height: 48px;
      }
      .info-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: relative;
        padding: 0 16px;
      }
      .info-container::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        background-color: rgba(0, 0, 0, 0.12);
      }
      .info-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .info-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .info-label {
        text-transform: uppercase;
        color: #666;
        font-size: 8px;
      }
      .info-value {
        font-weight: bold;
        font-size: 12px;
        color: #333;
      }
      .details-section {
        padding: 16px;
        border-top: 4px solid #eee;
      }
      .details-row {
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;
      }
      .details-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      .details-text {
        font-size: 12px;
        color: #666;
      }
    </style>
    
    <div class="popup-card">
      <div class="main-content">
        <div class="icon-container">
          <img class="alert-icon3" src="${getAlertIcon(alert.alert_type)}" alt="${alert.alerta}"/>
        </div>
        <div class="info-container">
          <div class="info-row" style="margin-bottom: 8px; padding-bottom: 8px; border-bottom: 3px solid #eee;">
            <div class="info-column" style="width: 50%">
              <span class="info-label">Tipo de alerta</span>
              <span class="info-value">${alert.alerta}</span>
            </div>
            <div style="width: 10%"></div>
            <div class="info-column" style="width: 30%">
              <span class="info-label">Unidad</span>
              <span class="info-value">${alert.unidad}</span>
            </div>
          </div>
          <div class="info-row">
            <div class="info-column" style="width: 30%">
              <span class="info-label">Hora</span>
              <span class="info-value">${alert.fecha}</span>
            </div>
            <div class="info-column" style="width: 30%">
              <span class="info-label">Chofer</span>
              <span class="info-value">${alert.chofer || 'No disponible'}</span>
            </div>
            <div class="info-column" style="width: 30%">
              <span class="info-label">Teléfono</span>
              <span class="info-value">${formatPhoneNumber(alert.telefono) || 'No disponible'}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="details-section">
        <div class="details-row">
          <img class="details-icon" src="${WarningIcon}" alt="Warning"/>
          <span class="details-text">${removeEmojis(alert.adicional.mensaje)}</span>
        </div>
        <div class="details-row">
          <img class="details-icon" src="${LocationIcon}" alt="Location"/>
          <span class="details-text">${alert.ubicacion.direccion}</span>
        </div>
      </div>
    </div>
  `;
};

// export const createPopupContent = (alert) => {
//   return `
//     <style>
//       .popup-card {
//         background: white;
//         border-left: 4px solid ${getStatusColor(alert.estado)};
//         border-top: 4px solid ${getStatusColor(alert.estado)};
//         min-width: 460px;
//         box-shadow: 0 2px 4px rgba(0,0,0,0.1);
//         border-radius: 4px;
//       }
//       .main-content {
//         display: flex;
//         flex-direction: row-reverse;
//         padding: 16px;
//         align-items: center; 
//       }
//       .icon-container {
//         width: 80px;
//         display: flex;
//         justify-content: center;
//          align-items: center;
//       }
//       .alert-icon3 {
//         width: 48px;
//         height: 48px;
//       }
//       .info-container {
//         flex: 1;
//         display: flex;
//         flex-direction: column;
//         gap: 8px;
//           position: relative;
//         padding: 0 16px;
//       }
//       .info-row {
//         display: flex;
//         justify-content: space-between;
//         width: 100%;
        
//       }
//       .info-column {
//         display: flex;
//         flex-direction: column;
//          alignItems: 'flex-start',
//         width: 30%;
//       }
//       .info-label {
//         text-transform: uppercase;
//         color: #666;
//         font-size: 8px;
//         margin-bottom: 2px;
//       }
//       .info-value {
//         font-weight: bold;
//         font-size: 12px;
//         color: #333;
//       }
//       .details-section {
//         padding: 16px;
//         border-top: 1px solid #eee;
//       }
//       .details-row {
//         display: flex;
//         alignItems: 'flex-start',
//         margin-bottom: 8px;
//       }
//       .details-icon {
//         width: 24px;
//         height: 24px;
//         margin-right: 8px;
//       }
//       .details-text {
//         font-size: 12px;
//         color: #666;
//       }
//     </style>
    
//     <div class="popup-card">
//       <div class="main-content">
//         <div class="info-container">
//           <div class="info-row">
//             <div class="info-column" style="width: 40%">
//               <span class="info-label">Tipo de alerta</span>
//               <span class="info-value">${alert.alerta}</span>
//             </div>
//             <div style="width: 30%"></div>
//             <div class="info-column" style="width: 30%">
//               <span class="info-label">Unidad</span>
//               <span class="info-value">${alert.unidad}</span>
//             </div>
//           </div>
          
//           <div class="info-row">
//             <div class="info-column" style="width: 40%">
//               <span class="info-label">Hora</span>
//               <span class="info-value">${alert.fecha}</span>
//             </div>
//             <div class="info-column" style="width: 30%">
//               <span class="info-label">Chofer</span>
//               <span class="info-value">${alert.chofer || 'No disponible'}</span>
//             </div>
//             <div class="info-column" style="width: 30%">
//               <span class="info-label">Teléfono</span>
//               <span class="info-value">${alert.telefono || 'No disponible'}</span>
//             </div>
//           </div>
//         </div>
//         <div class="icon-container">
//           <img class="alert-icon3" src="${getAlertIcon(alert.alerta)}" alt="${alert.alerta}"/>
//         </div>
//       </div>
      
//       <div class="details-section">
//         <div class="details-row">
//           <img class="details-icon" src="${WarningIcon}" alt="Warning"/>
//           <span class="details-text">${removeEmojis(alert.adicional.mensaje)}</span>
//         </div>
//         <div class="details-row">
//           <img class="details-icon" src="${LocationIcon}" alt="Location"/>
//           <span class="details-text">${alert.ubicacion.direccion}</span>
//         </div>
//       </div>
//     </div>
//   `;
// };

// // utils/popupUtils.js

// import { getAlertIcon } from './alertUtils';


// export const getStatusColor = (estado) => {
//     switch (estado.toLowerCase()) {
//       case 'rojo': return '#ff4444';
//       case 'amarillo': return '#ff9800';
//       default: return '#4CAF50';
//     }
//   };
  
//   export const createPopupContent = (alert) => {
//     return `
//       <style>
//         .popup-card {
//           background: white;
//           border-left: 4px solid ${getStatusColor(alert.estado)};
//           min-width: 460px;
//         }
//         .popup-header {
//           display: flex;
//           align-items: center;
//           justify-content: center;  
//           gap: 8px;
//           padding: 12px;
//           background-color: ${getStatusColor(alert.estado)}10;
//           border-bottom: 1px solid #eee;
//           text-align: center;      
//           width: 100%;            
//         }
//         .popup-header-text {
//           font-weight: bold;
//           color: ${getStatusColor(alert.estado)};
//           font-size: 16px;
//           text-align: center;     
//         }
//         .popup-content {
//           padding: 8px;
//         }
//         .info-row-group {
//           display: grid;
//           grid-template-columns: 1fr 1fr;
//           gap: 8px;
//           text-align: center;
//         }
//         .info-row {
//           display: flex;
//           flex-direction: column;
//           gap: 2px;
//           align-items: center;
//         }
//         .info-row.full-width {
//           grid-column: 1 / -1;
//           margin-top: 0px;
//         }
//         .info-label {
//           display: flex;
//           align-items: center;
//           gap: 8px;
//           font-weight: 600;
//           color: #666;
//           justify-content: center;
//         }
//         .info-value {
//           color: #333;
//           text-align: center;
//         }
//       </style>
      
//       <div class="popup-card">
//         <div class="popup-header">
//           <img src="${getAlertIcon(alert.alerta)}" alt="${alert.alerta}" style="width: 24px; height: 24px;"/>
//           <span class="popup-header-text">⚠️ ${alert.alerta}</span>
//         </div>
        
//         <div class="popup-content">
//           <div class="info-row-group">
//             <div class="info-row">
//               <span class="info-label">🚗 Unidad:</span>
//               <span class="info-value">${alert.unidad}</span>
//             </div>
//             <div class="info-row">
//               <span class="info-label">📅 Fecha:</span>
//               <span class="info-value">${alert.fecha}</span>
//             </div>
//             <div class="info-row">
//               <span class="info-label">👤 Chofer:</span>
//               <span class="info-value">${alert.chofer || 'No disponible'}</span>
//             </div>
//             <div class="info-row">
//               <span class="info-label">📱 Tel:</span>
//               <span class="info-value">${alert.telefono || 'No disponible'}</span>
//             </div>
            
//             <div class="info-row full-width">
//               <span class="info-label">💬 Mensaje:</span>
//               <span class="info-value">${alert.adicional.mensaje}</span>
//             </div>
  
//             <div class="info-row full-width">
//               <span class="info-label">📍 Dirección:</span>
//               <span class="info-value">${alert.ubicacion.direccion}</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     `;
//   };