// services/useGeocercas.js
import { useState, useEffect } from 'react';
import { geocercaService } from './apiService';

const useGeocercas = () => {
  const [geocercas, setGeocercas] = useState([]);
  const [selectedGeocercas, setSelectedGeocercas] = useState([]);

  useEffect(() => {
    const loadGeocercas = async () => {
      try {
        const response = await geocercaService.getGeocercas("2375ed39-6df8-4ae2-9f1b-6f11c6898209");
        if (response.statusCode === 200) {
          setGeocercas(response.data);
          setSelectedGeocercas(response.data.map(g => g.id));
        }
      } catch (error) {
        console.error('Error loading geocercas:', error);
      }
    };
    loadGeocercas();
  }, []);

  const handleSelection = (id) => {
    setSelectedGeocercas(prev => 
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    );
  };

  return { geocercas, selectedGeocercas, handleSelection };
};

export default useGeocercas;