// src/components/Wizard.js
import React, { useState, useEffect } from 'react';
import { notificacionService } from '../../services/apiService';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';

export const Wizard = ({ open, onClose, onSubmit }) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Report Name', 'Data Source', 'Select Fields'];
  const [reportName, setReportName] = useState('');
  const [dataSources, setDataSources] = useState([]);
  const [selectedDataSource, setSelectedDataSource] = useState('');
  const [fields, setFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);

  useEffect(() => {
    // Load available data sources
    setDataSources(['notificacionService','notificationomisiones']);
  }, []);

  useEffect(() => {
    // Load fields from the selected data source
    if (selectedDataSource) {
      loadFields(selectedDataSource);
    }
  }, [selectedDataSource]);

  const loadFields = async (dataSource) => {
    let data = [];
    let response;
    if (dataSource === 'notificacionService') {
       response = await notificacionService.getNotificacionesLocal();
      data = response.data || response;
    }
    else if (dataSource === 'notificationomisiones')
    {
         response = await notificacionService.getNotificacionesOmisiones();
        data = response.data || response;
      
    }
    const sampleData = data[0] || {};
    const extractedFields = extractFields(sampleData);
    setFields(extractedFields);
  };

  const extractFields = (obj, prefix = '') => {
    return Object.keys(obj).flatMap((key) => {
      const value = obj[key];
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        return extractFields(value, newKey);
      } else {
        return newKey;
      }
    });
  };

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleFinish = () => {
    onSubmit(reportName, selectedDataSource, selectedFields);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h6">Create New Report</Typography>
      </DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {activeStep === 0 && (
            <TextField
              label="Report Name"
              fullWidth
              variant="outlined"
              margin="dense"
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
            />
          )}
          {activeStep === 1 && (
            <Select
              label="Data Source"
              fullWidth
              variant="outlined"
              margin="dense"
              value={selectedDataSource}
              onChange={(e) => setSelectedDataSource(e.target.value)}
            >
              {dataSources.map((source) => (
                <MenuItem key={source} value={source}>
                  {source}
                </MenuItem>
              ))}
            </Select>
          )}
          {activeStep === 2 && (
            <div>
              <Typography variant="subtitle1">Select Fields:</Typography>
              {fields.map((field) => (
                <FormControlLabel
                  key={field}
                  control={
                    <Checkbox
                      checked={selectedFields.includes(field)}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setSelectedFields((prev) =>
                          checked
                            ? [...prev, field]
                            : prev.filter((f) => f !== field)
                        );
                      }}
                    />
                  }
                  label={field}
                />
              ))}
            </div>
          )}
        </motion.div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {activeStep > 0 && <Button onClick={handleBack}>Back</Button>}
        {activeStep < steps.length - 1 && (
          <Button
            onClick={handleNext}
            disabled={
              (activeStep === 0 && !reportName) ||
              (activeStep === 1 && !selectedDataSource)
            }
          >
            Next
          </Button>
        )}
        {activeStep === steps.length - 1 && (
          <Button
            onClick={handleFinish}
            disabled={selectedFields.length === 0}
          >
            Finish
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};