import {
  Divider,
  Grid,
  Checkbox,
  Box,
  Button,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { motion } from "framer-motion";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";

export default function ListaAudios({
  configuracion,
  actualizarComponente,
  handleGuardarPrincipal,
  idAlerta,
}) {
  /*Lista de alertas*/

  const soundsContext = require.context(
    "../../../../public/alertas_audio",
    false,
    /\.(mp3|wav)$/
  );
  const soundFiles = soundsContext.keys().map((key) => {
    var fileName = key
      .replace("./", "")
      .replace(/\.(mp3|wav)$/, "")
      .replace(/[^a-zA-Z0-9 ]/g, " ")
      .toUpperCase();
    const filePath = `${process.env.PUBLIC_URL}/alertas_audio/${key.replace(
      "./",
      ""
    )}`;
    return { fileName, filePath };
  });

  const initialAudio = {
    fileName: "",
    filePath: "",
  };
  const [selectedAudio, setSelectedAudio] = useState(initialAudio);

  const handleSelect = (audio) => {
    setSelectedAudio(audio);
    actualizarComponente("url_sonido", `${audio.filePath}`);
  };

  function isEnableButton() {
    return selectedAudio.filePath.length > 0 && idAlerta !== "";
  }

  useEffect(() => {
    //Validamos si el componente fue limpiado
    if (configuracion.url_sonido === "") {
      //refrescamos el state si es necesario
      if (selectedAudio.filePath !== "") {
        setSelectedAudio(initialAudio);
      }
    } else {
      // Si el componente está vacío, procedemos a cargar la información
      if (selectedAudio.filePath === "") {
        // Precargamos la UI con el sonido seleccionado desde la configuración
        const matchingAudio = soundFiles.find(
          (audio) => audio.filePath === configuracion.url_sonido
        );

        if (matchingAudio) {
          setSelectedAudio(matchingAudio); // Actualizamos el estado con el audio correspondiente

          // Enviamos los datos al componente padre
          actualizarComponente("url_sonido", matchingAudio.filePath);
        }
      }
    }
  }, [configuracion.url_sonido, soundFiles]);

  return (
    <Box
    height={220}
    sx={{
      display: "flex",
      alignItems: "stretch",
      alignContent: "space-between",
    }}
    overflow={"auto"}
  >
    <Box flexGrow={1} pt={1}>
      <Grid
        container
        pt={1}
        spacing={2}
        alignContent="flex-start"
        direction="column"
        height={220}
        overflow={"auto"}
        sx={{ alignItems: "baseline" }}
      >
        {soundFiles.map((audio) => (
          <Grid item key={audio.filePath} flexGrow={1}>
            <div>
              <Checkbox
                checked={selectedAudio.filePath === audio.filePath}
                onClick={() => handleSelect(audio)}
              />
              {audio.fileName}
            </div>
            <div>
              <audio controls style={{ height: 30 }}>
                <source
                  src={audio.filePath}
                  type={`audio/${audio.filePath
                    .replace("./", "")
                    .split(".")
                    .pop()}`}
                />
                Tu navegador no soporta el elemento de audio.
              </audio>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
    <Box
      pt={1}
      pb={2}
      width={160}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-around"}
    >
      <Box display={"flex"} flexGrow={1}></Box>
      <Box alignSelf={"flex-end"}>
        <Button
          variant="contained"
          color="primary"
          type="button"
          sx={{ width: "150px", padding: 2 }}
          onClick={() => {
            handleGuardarPrincipal();
          }}
          startIcon={<SaveIcon />}
          disabled={!isEnableButton()}
        >
          Guardar
        </Button>
      </Box>
    </Box>
    </Box>
  );
}
