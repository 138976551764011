// src/components/DetailTable.js
import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  IconButton,
  Tooltip,
  Typography,
  Collapse, Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button 
} from '@mui/material';
import {
  BarChart as BarChartIcon,
  PieChart as PieChartIcon,
  DonutLarge as DonutIcon,
  TableChart as TableIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { Map as MapIcon } from '@mui/icons-material';
import { ViewModule as PanelViewIcon } from '@mui/icons-material';
import PanelGroupWithChart from './PanelGroupWithChart';

import ChartComponent from './Chart'; // Asegúrate de que este componente maneja los tipos de gráfico
// Función para obtener valores anidados
const getNestedValue = (obj, path) => {
  if (obj.hasOwnProperty(path)) {
    return obj[path];
  }
  return path
    .split('.')
    .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), obj);
};

// Función para agrupar datos
// const groupData = (data, groupBy) => {
//   const grouped = data.reduce((acc, item) => {
//     const key = getNestedValue(item, groupBy) || 'N/A';
//     if (!acc[key]) {
//       acc[key] = { items: [], count: 0 };
//     }
//     acc[key].items.push(item);
//     acc[key].count++;
//     return acc;
//   }, {});

//   return Object.entries(grouped).map(([key, value]) => ({
//     groupKey: key,
//     items: value.items,
//     count: value.count,
//   }));
// };
const groupData = (data, groupBy,totalrecordss) => {
  // Get total records first
  const totalRecords = totalrecordss;//data.length;
  //console.log('Total records:', totalRecords);
 
  const grouped = data.reduce((acc, item) => {
    const key = getNestedValue(item, groupBy) || 'N/A';
    if (!acc[key]) {
      acc[key] = { items: [], count: 0 };
    }
    acc[key].items.push(item);
    acc[key].count++;
    return acc;
  }, {});

  return Object.entries(grouped).map(([key, value]) => ({
    groupKey: key,
    items: value.items,
    count: value.count,
    totalRecords // Add total records to each group
  }));
};

// const formatValue = (value) => {
//     if (value === null || value === undefined) return '';
//     if (typeof value === 'object') {
//       // Handle special cases for known object types
//       if (value.type && value.coordinates) return `${value.type}`;
//       if (value.Tipo) return `${value.Tipo}`;
//       // Convert object to string representation
//       return JSON.stringify(value);
//     }
//     return String(value);
//   };

// const LocationText = ({ address, coordinates }) => {
//   const [open, setOpen] = useState(false);

//   const handleOpenMap = () => {
//     const url = `https://www.google.com/maps?q=${coordinates[1]},${coordinates[0]}`;
//     window.open(url, '_blank');
//   };

//   return (
//     <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//       <Typography 
//         variant="body2" 
//         sx={{ 
//           cursor: 'pointer',
//           '&:hover': { textDecoration: 'underline' }
//         }}
//         onClick={handleOpenMap}
//       >
//         {address}
//       </Typography>
//       <Tooltip title="Ver en mapa">
//         <IconButton size="small" onClick={handleOpenMap}>
//           <MapIcon fontSize="small" />
//         </IconButton>
//       </Tooltip>
//     </Box>
//   );
// };

const LocationText = ({ address, coordinates, onOpenMap }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography 
        variant="body2" 
        sx={{ 
          cursor: 'pointer',
          '&:hover': { textDecoration: 'underline' }
        }}
        onClick={onOpenMap} // Usa la función pasada por props
      >
        {address}
      </Typography>
      <Tooltip title="Ver en mapa">
        <IconButton size="small" onClick={onOpenMap}>
          <MapIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

LocationText.propTypes = {
  address: PropTypes.string.isRequired,
  coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
  onOpenMap: PropTypes.func.isRequired,
};


const formatValue = (value, fieldName, row,handleOpenMap) => {
  if (value === null || value === undefined) return '';

  // Manejo especial para el campo Direccion
  if (fieldName === 'Direccion') {
    const coordinates = row['Location.coordinates'];
    if (coordinates) {
      return (
        <LocationText 
          address={value || 'Ver ubicación'} 
          coordinates={coordinates} 
          onOpenMap={() => handleOpenMap(coordinates)}
        />
      );
    }
    return value || '';
  }

  if (typeof value === 'object') {
    if (value.Tipo) return value.Tipo;
    return JSON.stringify(value);
  }
  
  return String(value);
};

  
// Componente de fila colapsable para grupos
const CollapsibleRow = ({ group, totalRecords,headers,excludeFields,handleOpenMap }) => {
  const [open, setOpen] = useState(false);
  const { groupKey, items, count } = group;

  return (
    <>
       <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
        }}
      >
        <TableCell 
          sx={{ 
            padding: '12px 8px',
            width: '100%',
            borderBottom: open ? '2px solid #f44336' : '1px solid rgba(224, 224, 224, 1)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton 
                size="small" 
                onClick={() => setOpen(!open)}
                sx={{ 
                  padding: '4px',
                  marginRight: '8px'
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              <Typography 
                variant="subtitle1"
                component="span"
                sx={{ 
                  fontWeight: 500,
                }}
              >
                {/* {formatValue(groupKey)} */}
                {formatValue(groupKey, {}, {})}
                {/* {formatValue(item[key], key, item)} */}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography
                variant="body2"
                component="span"
                color="text.secondary"
              >
                {count} de {totalRecords}
              </Typography>
              <Typography
                variant="body2"
                component="span"
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  padding: '2px 8px',
                  borderRadius: '12px',
                }}
              >
                {((count / totalRecords) * 100).toFixed(1)}%
              </Typography>
            </Box>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow>
  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box margin={1}>
        <Box sx={{ maxHeight: '400px', overflow: 'auto' }}> {/* Container with scroll */}
          <MuiTable 
            size="small" 
            aria-label="details"
            stickyHeader  // Enable sticky header
          >
            <TableHead>
              <TableRow>
              {Object.keys(items[0] || {})
                        .filter(key => !excludeFields.includes(key)) // Filtrar campos excluidos
                        .map((key) => (
                          <TableCell 
                            key={key}
                            sx={{
                              backgroundColor: 'background.paper',                      
                              fontWeight: 'bold'
                            }}
                          >
                            {headers && headers[key] ? headers[key] : key}
                          </TableCell>
                        ))}
                {/* {Object.keys(items[0] || {}).map((key) => (
                  <TableCell 
                    key={key}
                    sx={{
                      backgroundColor: 'background.paper', // Match your theme
                      fontWeight: 'bold'
                    }}
                  >
                    {headers && headers[key] ? headers[key] : key}
                  </TableCell>
                ))} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                    {Object.keys(item)
                          .filter(key => !excludeFields.includes(key)) // Filtrar campos excluidos
                          .map((key) => (
                            <TableCell key={key}>
                              {/* {formatValue(item[key], key, item)} */}
                              {formatValue(item[key], key, item, handleOpenMap)} {/* Pasa el callback */}

                            </TableCell>
                          ))}
                  {/* {Object.keys(item).map((key) => (
                    <TableCell key={key}>
                     
                      {formatValue(item[key], key, item)}

                    </TableCell>
                  ))} */}
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </Box>
      </Box>
    </Collapse>
  </TableCell>
</TableRow>

    </>
  );
};


CollapsibleRow.propTypes = {
  group: PropTypes.shape({
    groupKey: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
  }).isRequired,
};

const getFieldNames = (dataSource) => {
  if (dataSource === 'notificacionService') {
    return {
      economico: 'EconomicoAlias',
      tipoAlerta: 'DatosAlerta.NombreTipo'
    };
  } else {
    return {
      economico: 'NoEconomico',
      tipoAlerta: 'Nombre_TipoAlerta'
    };
  }
};

const DetailTable = forwardRef(({ data, fields, headers,defaultGroupBy,totalRecords,dataSource }, ref) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //const [groupBy, setGroupBy] = useState('');
  // const [groupBy, setGroupBy] = useState('EconomicoAlias');

  // const [viewType, setViewType] = useState('table');
  // const [viewSpecificGroupBy, setViewSpecificGroupBy] = useState({
  //   table: 'EconomicoAlias',
  //   panel: 'DatosAlerta.NombreTipo'
  // });
  const fieldNames = getFieldNames(dataSource);

  const [groupBy, setGroupBy] = useState(fieldNames.economico);
  const [viewType, setViewType] = useState('table');
  const [viewSpecificGroupBy, setViewSpecificGroupBy] = useState({
    table: fieldNames.economico,
    panel: fieldNames.tipoAlerta
  });

  const excludeFields = ['Location.type', 'Location.coordinates'];

  const [mapOpen, setMapOpen] = useState(false); // Estado para el modal
  const [mapCoordinates, setMapCoordinates] = useState(null); // Coordenadas para el mapa

  useEffect(() => {
    const newFieldNames = getFieldNames(dataSource);
    setGroupBy(newFieldNames.economico);
    setViewSpecificGroupBy({
      table: newFieldNames.economico,
      panel: newFieldNames.tipoAlerta
    });
  }, [dataSource]);
  
  // Función para abrir el modal con coordenadas específicas
  const handleOpenMap = (coordinates) => {
    setMapCoordinates(coordinates);
    setMapOpen(true);
  };

  const handleCloseMap = () => {
    setMapOpen(false);
    setMapCoordinates(null);
  };
  // 
  //comentar para dejar por defecto el agrupamiento por tabla
  // useImperativeHandle(ref, () => ({
  //   handleViewTypeChange: (newViewType) => {
  //     console.log('Changing view type to:', newViewType);
  //     setViewType(newViewType);
  //   },
  //   handleGroupByChange: (newGroupBy) => {
  //     console.log('Changing group by to:', newGroupBy);
  //     setGroupBy(newGroupBy);
  //   }
  // }));

  useImperativeHandle(ref, () => ({
    handleViewTypeChange: (newViewType) => {
     //console.log('Changing view type to:', newViewType);
      const newGroupBy = newViewType === 'panel' ? 
        viewSpecificGroupBy.panel : 
        viewSpecificGroupBy.table;
     //console.log('Setting new groupBy:', newGroupBy);
      setViewType(newViewType);
      setGroupBy(newGroupBy);
    },
    handleGroupByChange: (newGroupBy) => {
      //console.log('Manual groupBy change:', newGroupBy);
      setGroupBy(newGroupBy);
    }
  }));

  // Initialize grouping
  useEffect(() => {
    //console.log('Initial grouping setup with:', viewSpecificGroupBy.table);
    setGroupBy(viewSpecificGroupBy.table);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //Comentado para dejar por defecto el agrupamiento por tabla
  // useEffect(() => {
  //   setGroupBy(defaultGroupBy);
  // }, [defaultGroupBy]);

  // const columns = fields.map((field) => ({
  //   id: field,
  //   //label: field,
  //   label: headers && headers[field] ? headers[field] : field,
  //   minWidth: 100,
  // }));
  const columns = fields
    .filter(field => !excludeFields.includes(field))
    .map((field) => ({
      id: field,
      label: headers && headers[field] ? headers[field] : field,
      minWidth: 100,
    }));
  
  const groupedData = useMemo(() => {
    return groupBy ? groupData(data, groupBy,totalRecords) : [{ groupKey: 'Todos', items: data, count: data.length }];
  }, [data, groupBy]);

  const toolbar = (
    <Box sx={{ p: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel>Agrupar por</InputLabel>
        <Select
          value={groupBy}
          onChange={(e) => setGroupBy(e.target.value)}
          label="Agrupar por"
        >
          <MenuItem value="">Ninguno</MenuItem>
          {fields.map((field) => (
            <MenuItem key={field} value={field}>
              {field}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {groupBy && (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Tooltip title="Tabla">
            <IconButton 
              onClick={() => setViewType('table')}
              color={viewType === 'table' ? 'primary' : 'default'}
            >
              <TableIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Panel">
          <IconButton 
            onClick={() => setViewType('panel')}
            color={viewType === 'panel' ? 'primary' : 'default'}
          >
            <PanelViewIcon />
          </IconButton>
        </Tooltip>
          <Tooltip title="Gráfico de Barras">
            <IconButton 
              onClick={() => setViewType('BarChart')}
              color={viewType === 'bar' ? 'primary' : 'default'}
            >
              <BarChartIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Gráfico de Pie">
            <IconButton 
              onClick={() => setViewType('PieChart')}
              color={viewType === 'pie' ? 'primary' : 'default'}
            >
              <PieChartIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Gráfico de Dona">
            <IconButton 
              onClick={() => setViewType('DoughnutChart')}
              color={viewType === 'doughnut' ? 'primary' : 'default'}
            >
              <DonutIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );

  return (
    <Paper sx={{ width: '100%' , height:'100%'}}>
       {/* {toolbar}  */}
      
      {(!groupBy || (groupBy && viewType === 'table')) && (
        <>
          <TableContainer>
            <MuiTable stickyHeader aria-label="detail table">
            <TableHead>
            <TableRow>
                {!groupBy && <TableCell />}
                {/* {columns.map((column) => (
                <TableCell 
                    key={column.id} 
                    style={{ minWidth: column.minWidth }}
                >
                    {column.label}
                </TableCell>
                ))} */}
            </TableRow>
            </TableHead>
              <TableBody>
                {groupBy ? (
                  groupedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((group) => 
                    <CollapsibleRow key={group.groupKey} group={group} 
                     totalRecords={totalRecords}  headers={headers} 
                     excludeFields={excludeFields}
                     groupBy={groupBy}
                     handleOpenMap={handleOpenMap} 
                      />)
                ) : (
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell />
                        {columns.map((column) => {
                          const value = getNestedValue(row, column.id);
                          return (
                            <TableCell key={column.id}>
                              {/* {value !== null && value !== undefined ? value.toString() : ''} */}
                              {formatValue(value, column.id, row, handleOpenMap)} 
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                )}
              </TableBody>
            </MuiTable>
          </TableContainer>
          {groupBy && viewType === 'table' && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Filas por página"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}



{groupBy && viewType === 'panel' && (
  <Box sx={{ width: '100%', height: '100%' }}>
    <PanelGroupWithChart
      groupedData={groupedData}
      groupBy={groupBy}
      fields={fields}
      panelSettings={{
        showChart: true,
        chartType: 'BarChart'
      }}
      updatePanelSettings={() => {}}
      setGroupedData={() => {}}
    />
  </Box>
)}


{groupBy && viewType !== 'table' && (
    <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    style={{ flex: 1, height: '100%' }}
  >
    <Box sx={{ 
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      backgroundColor: 'background.paper',     
    }}>
      <ChartComponent
        type={viewType}  
            data={groupedData.map(group => ({
                label: group.groupKey,
                value: group.count
              }))}
              fields={['label', 'value']}
        fromPanel={false} 
      />
    </Box>
  </motion.div>

  // <Box 
  //   sx={{ 
  //     p: 2, 
  //     height: 400,
  //     position: 'relative',
  //     width: '100%'       
  //   }}
  // >
  //   <ChartComponent
  //     type={viewType}
  //     data={groupedData.map(group => ({
  //       label: group.groupKey,
  //       value: group.count
  //     }))}
  //     fields={['label', 'value']}
  //     fromPanel={false}
  //   />
  // </Box>
)}

<Dialog
        open={mapOpen}
        onClose={handleCloseMap}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Ubicación en el Mapa</DialogTitle>
        <DialogContent>
          {mapCoordinates ? (
            <iframe
              title="Mapa"
              src={`https://www.google.com/maps?q=${mapCoordinates[1]},${mapCoordinates[0]}&z=15&output=embed`}
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          ) : (
            <Typography>No se pudieron obtener las coordenadas.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMap}>Cerrar</Button>
        </DialogActions>
      </Dialog>


      {/* {groupBy && viewType === 'table' && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={groupedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Grupos por página"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )} */}
    </Paper>
  );
});

DetailTable.propTypes = {
  data: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
};

export default DetailTable;