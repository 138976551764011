export const injectPopupStyles = () => {
    // Evitar duplicar los estilos si ya existen
    if (!document.getElementById('leaflet-custom-styles')) {
      const styleSheet = document.createElement('style');
      styleSheet.id = 'leaflet-custom-styles';
      styleSheet.textContent = `
        .leaflet-popup-content {
          margin: 0 !important;
          min-width: 460px !important;
          width: 460px !important;
        }
        .leaflet-popup-content-wrapper {
          padding: 0 !important;
          border-radius: 8px !important;
          overflow: hidden !important;
        }
      `;
      document.head.appendChild(styleSheet);
    }
  };