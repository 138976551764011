import React from 'react';
import { Box, Typography, Paper, IconButton, Collapse, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore, Map as MapIcon } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { getAlertIcon } from '../../utils/alertUtils';
import WarningIcon from '../../assets/warning.svg';
import LocationIcon from '../../assets/location.svg';
import carmap from '../../assets/svg_images/car_.svg'; 

const getStatusColor = (estado) => {
  switch (estado.toLowerCase()) {
    case 'rojo': return '#ff4444';
    case 'amarillo': return '#ff9800';
    default: return '#4CAF50';
  }
};

const removeEmojis = (text) => {
  return text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF]|[\u0023-\u0039]\uFE0F?\u20E3|[\u3297\u3299]|[\u303D\u3030]|[\u24C2]|[\u25AA-\u25AB]|[\u25B6]|[\u25C0]|[\u25FB-\u25FE]|[\u00A9\u00AE]|[\u2122\u2139]|[\u0020-\u0039]\uFE0F|[\u2194-\u21AA]|[\u2B05-\u2B07]|[\u2934\u2935]|[\u2B1B\u2B1C]|[\u2B50]|[\u231A\u231B]|[\u23E9-\u23F3]|[\u23F8-\u23FA]|[\u25AA-\u25AB]|[\u25B6]|[\u25C0]|[\u25FB-\u25FE]|[\u00A9\u00AE]|[\u2122\u2139]|[\u0020-\u0039]\uFE0F|[\u2194-\u21AA]|[\u2B05-\u2B07]|[\u2934\u2935]|[\u2B1B\u2B1C]|[\u2B50]|[\u231A\u231B]|[\u23E9-\u23F3]|[\u23F8-\u23FA]|⚡)/g, '');
};


const ClonedAlertOverlay = ({ alert, onClose, onMapIconClick }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const theme = useTheme();
  const borderColor = getStatusColor(alert.estado);

  return (
    <Paper
      elevation={isOpen ? 6 : 3}
      sx={{
        mt: 1,
        borderLeft: `4px solid ${borderColor}`,
        borderTop: `4px solid ${borderColor}`,
        transition: 'all 0.3s ease',
        top: '35%',
        position: 'absolute',
        width: '30%'
      }}
      component={motion.div}
      whileHover={{ scale: isOpen ? 1.02 : 1.01 }}
    >
      <Box 
        onClick={() => setIsOpen(!isOpen)}
        sx={{ 
          display: 'flex', 
          padding: 2,
          cursor: 'pointer'
        }}
        className="alert-container"
      >
        <Box sx={{ width: 80, display: 'flex', justifyContent: 'center' }} className="alert-iconoalert">
          <img 
            src={getAlertIcon(alert.alerta)} 
            alt={alert.alerta}
            style={{ width: 48, height: 48 }}
          />
        </Box>

        <Box 
          sx={{
            flex: 1, display: 'flex', flexDirection: 'column', gap: 0.5,
            position: 'relative',
            px: 2,
           '&::before': {
             content: '""',
             position: 'absolute',     
             left: '-5px',
             top: 0,
             height: '100%',
             width: '2px',
             backgroundColor: (theme) => theme.palette.divider
           }
         }} 
         className="alert-info">
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            width: '100%',
            mb: 1,
            pb: 1,
            borderBottom: '3px solid',
            borderColor: 'divider',   
          }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'flex-start',
              width: '50%'
            }} className="alert-alerta">
              <Typography variant="overline" color="textSecondary" style={{ fontSize: '8px' }}>
                Tipo de alerta
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                {alert.alerta}
              </Typography>
            </Box>

            <Box sx={{ width: '10%' }} />

            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'flex-start',
              width: '30%'
            }} className="alert-unidad">
              <Typography variant="overline" color="textSecondary" style={{ fontSize: '8px' }}>
                Unidad
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                {alert.unidad}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            width: '100%'
          }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'flex-start',
              width: '30%'
            }} className="alert-fecha">
              <Typography variant="overline" color="textSecondary" style={{ fontSize: '8px' }}>
                Hora
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                {alert.fecha}
              </Typography>
            </Box>
            
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'flex-start',
              width: '30%'
            }} className="alert-chofer">
              <Typography variant="overline" color="textSecondary" style={{ fontSize: '8px' }}>
                Chofer
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                {alert.chofer || 'No disponible'}
              </Typography>
            </Box>

            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'flex-start',
              width: '30%'
            }} className="alert-telefono">
              <Typography variant="overline" color="textSecondary" style={{ fontSize: '8px' }}>
                Teléfono
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                {alert.telefono || 'No disponible'}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 1,
          ml: 1
        }}>
          <IconButton>
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <IconButton 
            className="alert-mapredirect"
            onClick={(e) => {
              e.stopPropagation();
              onMapIconClick(alert.ubicacion, alert);
            }}
            sx={{ 
              '& img': {
                width: 36,
                height: 36
              }
            }}
          >
            <img src={carmap} alt="Car location" />
          </IconButton>
        </Box>
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <Box p={2}   
              sx={{
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '10px',
                  background: 'linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)'
                }
              }}           
              >
          <Box 
            display="flex" 
            alignItems="center"
            mb={1}
            className="alert-mensaje"
          >
            <Box 
              display="flex" 
              alignItems="center" 
              alignSelf="stretch"
              mr={1}
            >
              <img
                src={WarningIcon}
                alt="Warning"
                style={{ 
                  width: '24px', 
                  height: '24px',
                  flexShrink: 0,
                }}
              />
            </Box>
            <Typography variant="caption">
            {removeEmojis(alert?.adicional?.mensaje) || 'Sin mensaje'}
            </Typography>
          </Box>

          <Box 
            display="flex" 
            alignItems="center"
            className="alert-direccion"
          >
            <Box 
              display="flex" 
              alignItems="center" 
              alignSelf="stretch"
              mr={1}
            >
              <img
                src={LocationIcon}
                alt="Location"
                style={{ 
                  width: '24px', 
                  height: '24px',
                  flexShrink: 0,
                }}
              />
            </Box>
            <Typography variant="caption">
              {alert?.ubicacion?.direccion || 'No disponible'}
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default ClonedAlertOverlay;

// // ClonedAlertOverlay.js

// import React from 'react';
// import { Box, Typography, Paper, IconButton, Collapse, useTheme } from '@mui/material';
// import { ExpandLess, ExpandMore, Map as MapIcon, Close as CloseIcon } from '@mui/icons-material';
// import { motion } from 'framer-motion';
// import { getAlertIcon, getTextEmoji } from '../../utils/alertUtils'; // Asegúrate de ajustar el path según tu estructura

// // Función auxiliar para obtener el color del estado
// const getStatusColor = (estado) => {
//   switch (estado.toLowerCase()) {
//     case 'rojo':
//       return '#ff4444';
//     case 'amarillo':
//       return '#ff9800';
//     default:
//       return '#4CAF50';
//   }
// };

// const ClonedAlertOverlay = ({ alert, onClose, onMapIconClick }) => {
//   const theme = useTheme();
//   const [isOpen, setIsOpen] = React.useState(true); // Mantener la alerta expandida por defecto

//   const handleCollapseClick = () => {
//     setIsOpen(!isOpen);
//   };

//   const borderColor = getStatusColor(alert.estado);

//   return (
    
//       <Paper
//         elevation={isOpen ? 6 : 3}
//         sx={{
//           mt: 1,
//           borderLeft: `4px solid ${borderColor}`,
//           borderTop: `4px solid ${borderColor}`,
//           transition: 'all 0.3s ease',
//           ...(isOpen && {
//             boxShadow: `0 0 0 2px transparent`,
//             transform: 'scale(1.02)',
//           }),
//           cursor: 'pointer',
//           top: '35%',
//           position: 'absolute',
//           width: '30%'
//         }}
//         component={motion.div}
//         whileHover={{ scale: isOpen ? 1.02 : 1.01 }}
//         onClick={handleCollapseClick}
//       >
//         <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, padding: 1 }}>
//           <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="alert-iconoalert">
//             <img
//               src={getAlertIcon(alert.alerta)}
//               alt={alert.alerta}
//               style={{ width: 56, height: 56, verticalAlign: 'middle' }}
//             />
//           </Box>

//           <Box flex={1} ml={2}>
//             <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
//               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="alert-unidad">
//                 <span style={{ fontSize: '24px' }}>{getTextEmoji('unidad')}</span>
//                 <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
//                   {alert.unidad}
//                 </Typography>
//               </Box>
//               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="alert-chofer">
//                 <span style={{ fontSize: '24px' }}>{getTextEmoji('chofer')}</span>
//                 <Typography variant="body2" color="textSecondary">
//                   {alert.chofer || 'No disponible'}
//                 </Typography>
//               </Box>
//               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="alert-telefono">
//                 <span style={{ fontSize: '24px' }}>{getTextEmoji('telefono')}</span>
//                 <Typography variant="body2" color="textSecondary">
//                   {alert.telefono || 'No disponible'}
//                 </Typography>
//               </Box>
//             </Box>
//             <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} 
//             className="alert-fecha">
//               <span style={{ fontSize: '24px' }}>{getTextEmoji('fecha')}</span>
//               <Typography variant="body2" color="textSecondary">
//                 {alert.fecha}
//               </Typography>
//             </Box>
//           </Box>
//           <Box className="alert-mapredirect"> 
//             <IconButton
//             onClick={(e) => {
//               e.stopPropagation();
//               onMapIconClick(alert.ubicacion, alert);
//             }} 
//           >
//             <MapIcon />
//           </IconButton>
//           </Box>
          
//           {isOpen ? <ExpandLess /> : <ExpandMore />}
//         </Box>
//         <Collapse in={isOpen} timeout="auto" unmountOnExit>
//           <Box p={2}>
//             <Typography variant="body2" className="alert-alerta">
//               <span style={{ fontSize: '24px' }}>{getTextEmoji('alerta')}</span> Alerta: {alert.alerta}
//             </Typography>
//             <Typography variant="body2" className="alert-mensaje">
//               <span style={{ fontSize: '24px' }}>{getTextEmoji('mensaje')}</span> Descripción: {alert?.adicional?.mensaje || 'Sin mensaje'}
//             </Typography>
//             <Typography variant="body2" className="alert-direccion">
//               <span style={{ fontSize: '24px' }}>{getTextEmoji('direccion')}</span> Ubicación: {alert?.ubicacion?.direccion || 'No disponible'}
//             </Typography>
//           </Box>
//         </Collapse>
//       </Paper>

//   );
// };

// export default ClonedAlertOverlay;