// src/components/Toolbar.js
import PropTypes from 'prop-types';

import React, { useState } from 'react';
import { 
  AppBar,
  Toolbar as MuiToolbar,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Menu,  Typography,
  ListItemIcon,
  ListItemText,
  Divider,  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,TextField
} from '@mui/material';
import { 
  AddCircleOutline as AddIcon,
  Save as SaveIcon,
  FolderOpen as LoadIcon,
  Description as ReportIcon,
  DeleteOutline as DeleteIcon,
  TableChart,
  BarChart,
  PieChart,

  // AddCircleOutline as AddIcon,
  // BarChart,
  // PieChart,
  // TableChart,
  // Save as SaveIcon,
  // FolderOpen as LoadIcon,
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { configurationService } from './configurationService';


const Toolbar = ({ onOpenWizard, onSelectVisualization,
  onLoadConfig,
  hasConfiguration,  
  onSaveConfig,  
  onDeleteConfig,  
  savedConfigs = [],
  currentConfigId,
  getConfigData, 
 }) => {

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [configName, setConfigName] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSave = async () => {
    const configData = getConfigData();
    if (!currentConfigId) {
      // New configuration
      setSaveDialogOpen(true);
    } else {
      // Editing existing configuration
      setConfirmDialogOpen(true);
    }
  };

  const handleSaveNewConfig = async () => {
    const configData = getConfigData();
    const newConfig = {
      ...configData,
      name: configName,
    };
    await configurationService.saveConfig(newConfig);
    setSaveDialogOpen(false);
    setConfigName('');
  };

  const handleUpdateConfig = async () => {
    const configData = getConfigData();
    await configurationService.updateConfig(currentConfigId, configData);
    setConfirmDialogOpen(false);
  };
  
  const handleLoadConfig = (configId) => {
    onLoadConfig(configId);
    setAnchorEl(null);
  };

  const handleDeleteConfig = (event, configId) => {
    try {
      if (typeof onDeleteConfig !== 'function') {
        console.error('onDeleteConfig prop is not a function');
        return;
      }
      onDeleteConfig(configId);
    } catch (error) {
      console.error('Error deleting configuration:', error);
    }
  };


  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <AppBar position="static" color="default" elevation={1}>
        <MuiToolbar>

        {process.env.REACT_APP_SUPERUSERACTIVATE === 'true' && (
          <>

          

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={onOpenWizard}
            sx={{ mr: 2 }}
          >
            Create Report
          </Button>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Visualization Type</InputLabel>
            <Select
              label="Visualization Type"
              onChange={(e) => onSelectVisualization(e.target.value)}
              defaultValue="Table"
            >
              <MenuItem value="Table">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <TableChart /> Table
                </Box>
              </MenuItem>
              <MenuItem value="BarChart">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <BarChart /> Bar Chart
                </Box>
              </MenuItem>
              <MenuItem value="PieChart">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <PieChart /> Pie Chart
                </Box>
              </MenuItem>
            </Select>
          </FormControl>

         
<IconButton onClick={handleSave}>
        <SaveIcon />
      </IconButton>
      {/* Save Dialog for new configuration */}
      <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
        <DialogTitle>Save Configuration</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Configuration Name"
            fullWidth
            value={configName}
            onChange={(e) => setConfigName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveNewConfig} disabled={!configName}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog for updating existing configuration */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Update Configuration</DialogTitle>
        <DialogContent>
          Are you sure you want to overwrite the existing configuration?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleUpdateConfig}>Yes</Button>
        </DialogActions>
      </Dialog>
      </>
        )}

        <Button
          variant="outlined"
          startIcon={<LoadIcon />}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{ mr: 2 }}
        >
          Load Configuration
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            sx: {
              minWidth: 250,
              maxHeight: 400,
            },
          }}
        >
          {savedConfigs.length === 0 ? (
            <MenuItem disabled>
              <Typography variant="body2" color="text.secondary">
                No saved configurations
              </Typography>
            </MenuItem>
          ) : (
            savedConfigs.map((config) => (
              <MenuItem
                key={config.id}
                onClick={() => handleLoadConfig(config.id)}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 1,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <ListItemIcon>
                    <ReportIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={config.name}
                    secondary={new Date(config.updatedAt).toLocaleDateString()}
                  />
                </Box>
                <IconButton
                  size="small"
                  onClick={(e) => handleDeleteConfig(e, config.id)}
                  sx={{
                    '&:hover': {
                      color: 'error.main',
                    },
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </MenuItem>
            ))
          )}
        </Menu>

        </MuiToolbar>
      </AppBar>
    </motion.div>
  );
};

Toolbar.propTypes = {
  onOpenWizard: PropTypes.func.isRequired,
  onSelectVisualization: PropTypes.func.isRequired,
  onLoadConfig: PropTypes.func.isRequired,
  hasConfiguration: PropTypes.bool,
  onSaveConfig: PropTypes.func.isRequired,
  onDeleteConfig: PropTypes.func.isRequired,
  savedConfigs: PropTypes.array,
  currentConfigId: PropTypes.string,
  getConfigData: PropTypes.func.isRequired
};

export default Toolbar;