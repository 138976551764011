// utils/markerUtils.js
import { getAlertIcon } from './alertUtils';

import vehicleIcon from '../assets/svg_images/svg_vehiculo.svg';

export const createVehicleMarkerHTML = (alert) => {
  const rotation = alert.cog ? parseInt(alert.cog) : 0;

  return `
    <div class="vehicle-marker-container" style="position: relative;">
      <svg width="65" height="75" viewBox="-10 -10 70 70" xmlns="http://www.w3.org/2000/svg"
      style="transform: rotate(${rotation}deg); transform-origin: center;">
        <g width="100%" height="100%">
          <path id="obj_objeto" stroke="black" fill="#000000" d="m24.49095,0.21322l-11.759,0c-3.117,0 -5.643,3.467 -5.643,6.584l0,34.804c0,3.116 2.526,5.644 5.643,5.644l11.759,0c3.116,0 5.644,-2.527 5.644,-5.644l0,-34.804c-0.002,-3.117 -2.528,-6.584 -5.644,-6.584zm4.655,14.188l0,11.665l-2.729,0.351l0,-4.806l2.729,-7.21zm-1.432,-3.415c-1.016,3.9 -2.219,8.51 -2.219,8.51l-13.768,0l-2.222,-8.51c0.001,0 8.884,-3.018 18.209,0zm-16.877,10.94l0,4.492l-2.73,-0.349l0,-11.354l2.73,7.211zm-2.73,16.225l0,-10.359l2.73,0.343l0,8.196l-2.73,1.82zm1.557,2.944l2.218,-3.336l13.771,0l2.219,3.336l-18.208,0zm16.753,-5.077l0,-7.872l2.729,-0.355l0,10.048l-2.729,-1.821z"/>
          <circle cx="8.5218" cy="10.50861" r="8.63539" fill="white" stroke="black"/>
          <image 
            href="${getAlertIcon(alert.alert_type)}" 
            width="17" 
            height="17"
            x="0.0218" 
            y="2.00861" 
            alt="${alert.alerta}"
          />
        </g>
      </svg>
    </div>
  `;
};


// export const createVehicleMarkerHTML = (alert) => {
//     return `
//       <div class="vehicle-marker-container">
       
//         <img src="${vehicleIcon}" class='vehicle-icon' style="width: 60px; height: 60px;"/>

//         <div class="alert-icon ${alert.alerta.toLowerCase().replace(/\s+/g, '-')}" style="position: absolute; z-index: 1000; bottom: -5px; right: -5px;">
//           <img src="${getAlertIcon(alert.alerta)}" alt="${alert.alerta}" style="width: 20px; height: 20px;"/>
//         </div>
       
//       </div>
//     `;
//   };

  //   const markerDiv = document.createElement('div');
//   markerDiv.className = 'vehicle-marker-container';
//   markerDiv.innerHTML = `
//     <div class="vehicle-icon">🚗</div> <div class="vehicle-icon">🚗</div>
//     <div class="alert-icon ${alert.alerta.toLowerCase().replace(/\s+/g, '-')}">
//         <img src="${getAlertIcon(alert.alerta)}" alt="${alert.alerta}" style="width: 20px; height: 20px;"/>
//     </div>
//   `;
{/* <span style="position: absolute; top: -10px; left: 50%; transform: translateX(-50%); background: white; padding: 2px 5px; border-radius: 3px; font-size: 12px;">
${alert.unidad}
</span> */}