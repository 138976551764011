// src/pages/Home.js
import React from 'react';
import { Typography, Paper, Grid } from '@mui/material';
import MainLayout from '../layouts/MainLayout';

function Home() {
  return (
   
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              Dashboard
            </Typography>
            <Typography variant="body1">
              Bienvenido al sistema de gestión de omisiones
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    
  );
}

export default Home;