// MonitorStandalone.js
import React from 'react';
import Monitor from './index';
import { LoadingProvider } from '../../contexts/LoadingContext';

const MonitorStandalone = () => {
  return (
    <LoadingProvider>
      <Monitor 
        standalone={true}
        onNewAlert={(alert) => {
          // Comunicar con la ventana principal
          window.opener?.postMessage({
            type: 'ALERT_UPDATE',
            alert
          }, '*');
        }}
      />
    </LoadingProvider>
  );
};

export default MonitorStandalone;