// PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext'; // Actualizado

function PrivateRoute({ children }) {
  const { autenticado } = useContext(AuthContext);

  return autenticado ? children : <Navigate to="/login" replace />;
}

export default PrivateRoute;