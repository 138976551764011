//Componentes de la UI
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import Brightness1SharpIcon from "@mui/icons-material/Brightness1Sharp";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close"; // Necesario para botones de cierre
import CancelIcon from "@mui/icons-material/Cancel";
import PeopleIcon from "@mui/icons-material/People"; // Necesario para contactos

//Componentes para alertas
import HorarioConfigForm from "../alertas/components/HorarioConfigForm";
import ListaVehiculo from "../alertas/components/VehiculoForm";

//Servicios API
import { alertaService } from "../../services/apiService";
import ListaGeocercas from "../alertas/components/GeocercaForm";
import ListaAudios from "../alertas/components/AlertaSonidoForm";
import ListaVehiculoCompare from "../alertas/components/VehiculoCompareForm";
import ModalListaContactos from "../contactos/contactos-alertas/components/ListaContactosAlertas";

//Estilo personalizado para la ventana modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

//Modulo Principal del componente -------------------------------------------------------------
const TableModule = ({ alertList, onSelectionChange }) => {
  //estados del componente alerta
  const [searchText, setSearchText] = useState(""); //Buscador de la tabla
  const [selectedRows, setSelectedRows] = useState([]); //Elementos seleccionados tabla
  const [alertaId, setAlertaId] = useState(""); // ID de la alerta seleccionada
  const [daysSel, setDaysSel] = React.useState(
    []
  ); /*Para el selector individual de dias*/
  const [openModalHours, setOpenHour] = React.useState(false); //Para la ventana modal (abrir horarios)
  const [openModalVehicle, setOpenVh] = React.useState(false); //Para la ventana modal (abrir vehiculos)
  const [openModalVehicleCompare, setOpenVhCo] = React.useState(false); //Para la ventana modal (abrir vehiculos)
  const [configuracion, setConfiguracion] = useState({
    //Configuracion de la vista
    nombre: "",
    tipo_alerta: {},
    geocerca: [],
    vehiculo: [],
    vehiculo_compare: [],
    contactos: [],
    riesgo: "",
    tiempo: 0,
    veces: 0,
    repetir: 1,
    velocidad_movimiento: 0,
    distancia_vehiculos: 0,
    tipo_marcado: 0,
    url_sonido: "",
    horario: [],
  });
  //geocercas
  const [openGeocercas, setOpenGeocercas] = React.useState(false);

  //audio
  const [openAudios, setOpenAudios] = React.useState(false);

  //contactos
  const [openContactos, setOpenContactos] = React.useState(false);

  //Se definenen las columnas de la tabla
  const columns = [
    { field: "fecha_alta", headerName: "Fecha", width: 200 },
    { field: "nombre_usuario", headerName: "Usuario", width: 200 },
    { field: "TipoAlerta", headerName: "Tipo de alerta", width: 200 },
    { field: "nombre", headerName: "Detalle", width: 200 },
    {
      field: "geocerca",
      type: "actions",
      headerName: "Geocerca",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<AutoAwesomeMotionIcon />}
          label="Modificar Geocercas"
          onClick={() => handleGeocercaProcess(params.row)}
        />,
      ],
    },
    {
      field: "vehiculo",
      type: "actions",
      headerName: "Vehiculo",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<LocalShippingIcon />}
          label="Modificar Vehiculos"
          onClick={() => {
            handleClickVehicle(params.row);
          }}
        />,
      ],
    },
    {
      field: "vehiculo_compare",
      type: "actions",
      headerName: "Vehiculos a comparar",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<LocalShippingIcon />}
          label="Modificar Vehiculos"
          onClick={() => {
            handleClickVehicleCompare(params.row);
          }}
          disabled={
            params.row.tipo_alerta.nombre === "Acercamiento" ? false : true
          }
        />,
      ],
    },
    {
      field: "horario",
      type: "actions",
      headerName: "Horario",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EventAvailableIcon />}
          label="Modificar Horario"
          onClick={() => handleClickHours(params.row)}
        />,
      ],
    },
    {
      field: "url_sonido",
      type: "actions",
      headerName: "Audio",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VolumeUpIcon />}
          label="Modificar Audio"
          onClick={() => handleAudioProcess(params.row)}
        />,
      ],
    },
    {
      field: "contactos",
      type: "actions",
      headerName: "Contactos",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<PeopleIcon />}
          label="Modificar Contactos"
          onClick={() => handleContactosProcess(params.row)}
        />,
      ],
    },
    {
      field: "nombre_riesgo",
      type: "actions",
      headerName: "Importancia",
      width: 150,
      getActions: (params) => {
        let colorStr = "";

        switch (params.row.nombre_riesgo) {
          case "Alto":
            colorStr = "#f54242";
            break;

          case "Bajo":
            colorStr = "#78db53";
            break;

          case "Medio":
            colorStr = "#dbd653";
            break;

          default:
            colorStr = "#a8a8a8";
            break;
        }

        return [
          <GridActionsCellItem
            icon={<Brightness1SharpIcon />}
            label="Modificar Audio"
            sx={{ color: `${colorStr}` }}
          />,
        ];
      },
    },
  ];

  /***** Eventos ******************************
   *********************************************/

  const resetForm = () => {
    setConfiguracion({
      //Configuracion de la vista
      nombre: "",
      tipo_alerta: {},
      geocerca: [],
      vehiculo: [],
      vehiculo_compare: [],
      contactos: [],
      riesgo: "",
      tiempo: 0,
      veces: 0,
      repetir: 1,
      velocidad_movimiento: 0,
      distancia_vehiculos: 0,
      tipo_marcado: 0,
      url_sonido: "",
      horario: [],
    });

    setAlertaId(null);
  };

  // Evento para cargar los datos de la ventana modal (Para los procesos de la tabla)
  const loadAlertaById = async (id) => {
    if (!id) {
      console.warn("No se seleccionó ningún ID para cargar la alerta.");
      return; // Si no hay un ID, no hace nada
    }
    try {
      const filtros = { id: id };
      const response = await alertaService.getAlertList(filtros);
      if (response && response.statusCode === 200) {
        setConfiguracion(response.data[0]); // Cargar los datos en el estado de configuración
        setAlertaId(id); // Establecer el ID de la alerta
      } else {
        toast.error("Error al cargar los datos del horario seleccionado");
      }
    } catch (error) {
      toast.error("Error al cargar la alerta seleccionada");
    }
  };

  // Método para guardar toda la alerta (creación o edición al api)
  const handleGuardarPrincipal = async () => {
    const jsonCompleto = {
      id: alertaId || "",
      ...configuracion, // Usamos todo el estado como base
    };

    try {
      if (alertaId) {
        // Actualizar la alerta existente
        const response = await alertaService.updateAlerta(jsonCompleto);
        if (response && response.statusCode === 200) {
          toast.success("Alerta Actualizada exitosamente");
        } else {
          toast.error(response?.message || "Error al actualizar alerta");
        }
      }
    } catch (error) {
      console.error("Error al guardar alerta:", error);
      toast.error("Error al guardar la alerta");
    }
  };

  //Evento para actualizar el componente principal
  const actualizarComponente = (clave, datos) => {
    setConfiguracion((prev) => ({
      ...prev,
      [clave]: datos,
    })); // Solo actualiza la clave correspondiente
  };

  //Evento para cerrar el modal
  const handleClose = () => {
    setOpenHour(false);
    setOpenVh(false);
    resetForm();
  };

  //Evento para cerrar el modal
  const handleCloseCompare = () => {
    setOpenHour(false);
    setOpenVhCo(false);
    resetForm();
  };

  //Evento para abir el modal
  const showHours = () => {
    //limpia el formulario
    resetForm();

    //Muestra el modal
    setOpenHour(true);
  };

  //Evento para cerrar el modal
  const showVehicles = () => {
    resetForm();
    setOpenVh(true);
  };

  //Evento para cerrar el modal
  const showVehiclesCompare = () => {
    resetForm();
    setOpenVhCo(true);
  };

  const showContactos = () => {
    resetForm();
    setOpenContactos(true);
  };

  //Evento modificar alerta (vehiculo)
  const handleClickVehicle = (selected) => {
    //Aqui va actualizar la UI con el elemento seleccionado
    loadAlertaById(`${selected.id}`);

    //abrir modal horas
    showVehicles();
  };

  //Evento modificar alerta (comparar vehiculo)
  const handleClickVehicleCompare = (selected) => {
    //Aqui va actualizar la UI con el elemento seleccionado
    loadAlertaById(`${selected.id}`);

    //abrir modal
    showVehiclesCompare();
  };

  //Evento modificar alerta (horario)
  const handleClickHours = (selected) => {
    //Aqui va actualizar la UI con el elemento seleccionado
    loadAlertaById(`${selected.id}`);
    //abrir modal horas
    showHours();
  };

  //Actualizacion de la UI
  React.useEffect(() => {
    //Validar si el arreglo tiene un elemnto
    if (selectedRows.length === 1) {
      //obtiene el identificador para el componente
      setAlertaId(`${selectedRows[0].id}`);
    }
  }, [selectedRows]);

  // Mapea la data a modelo de vista
  const listViewModel = alertList.map((alert, Index) => {
    alert.TipoAlerta = alert.tipo_alerta.nombre;
    alert.id = alert.id || Index;
    return alert;
  });

  // Filtrar las filas según el texto de búsqueda
  const filteredAlerts = listViewModel.filter((alert) => {
    // Comprobamos que cada valor de la fila se pueda convertir a string y que contenga la búsqueda
    return Object.values(alert).some((value) => {
      // Convertir el valor a string y comprobar si incluye el texto de búsqueda
      const strValue = value ? value.toString().toLowerCase() : "";
      return strValue.includes(searchText.toLowerCase());
    });
  });

  //geocercas
  const handleGeocercaProcess = (selected) => {
    // Carga los datos específicos de la alerta
    loadAlertaById(`${selected.id}`);
    // Abre el modal de geocercas
    setOpenGeocercas(true);
  };

  //audio
  const handleAudioProcess = (selected) => {
    // Carga los datos específicos de la alerta
    loadAlertaById(`${selected.id}`);
    // Abre el modal de audios
    setOpenAudios(true);
  };

  //Contactos
  const handleContactosProcess = (selected) => {
    loadAlertaById(`${selected.id}`);
    showContactos();
  };

  //Retorna la UI del componente -----------------------------------
  return (
    <>
      <Box sx={{ width: "100%", mt: 2 }}>
        {/* Botón Buscar */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <TextField
            label="Buscar"
            variant="outlined"
            size="small"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Box>
        <Box sx={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={filteredAlerts}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            checkboxSelection
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = filteredAlerts.filter((row) =>
                selectedIDs.has(row.id)
              );

              setSelectedRows(selectedRows);
              onSelectionChange(selectedRows); // Notifica al padre
            }}
            sx={{
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
          />
        </Box>
      </Box>

      {/* Modal Horarios */}
      <Modal
        open={openModalHours}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 1200 }}>
          {/* Botón de cierre */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CancelIcon />
          </IconButton>
          <h2 id="child-modal-title">Configuracion de horarios</h2>

          <Divider
            sx={{
              borderBottomWidth: 2,
              borderColor: "red",
              marginBottom: 2,
            }}
          />

          <Box sx={{ padding: 2 }}>
            <HorarioConfigForm
              configuracion={configuracion}
              actualizarComponente={actualizarComponente} // metodo que actualiza json principal
              handleGuardarPrincipal={handleGuardarPrincipal} // Pasamos el método para guardar todo al api
              idAlerta={alertaId} // Pasamos idAlerta
              daysSel={daysSel} //Lista de dias seleccionados
              setDaysSel={setDaysSel} //Evento para actualizar la UI
            />
          </Box>
        </Box>
      </Modal>

      {/* Modal Vehiculos */}
      <Modal
        open={openModalVehicle}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 1000 }}>
          {/* Botón de cierre */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CancelIcon />
          </IconButton>
          <h2 id="child-modal-title">Configuracion de vehiculos</h2>

          <Divider
            sx={{
              borderBottomWidth: 2,
              borderColor: "red",
              marginBottom: 2,
            }}
          />

          <Box sx={{ padding: 2 }}>
            <ListaVehiculo
              configuracion={configuracion}
              actualizarComponente={actualizarComponente}
              handleGuardarPrincipal={handleGuardarPrincipal}
              idAlerta={alertaId}
            />
          </Box>
        </Box>
      </Modal>

      {/* Modal Vehiculos Comparar */}
      <Modal
        open={openModalVehicleCompare}
        onClose={handleCloseCompare}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 1000 }}>
          {/* Botón de cierre */}
          <IconButton
            aria-label="close"
            onClick={handleCloseCompare}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CancelIcon />
          </IconButton>
          <h2 id="child-modal-title">Configuracion de vehiculos a comparar</h2>

          <Divider
            sx={{
              borderBottomWidth: 2,
              borderColor: "red",
              marginBottom: 2,
            }}
          />

          <Box sx={{ padding: 2 }}>
            <ListaVehiculoCompare
              configuracion={configuracion}
              actualizarComponente={actualizarComponente}
              handleGuardarPrincipal={handleGuardarPrincipal}
              idAlerta={alertaId}
            />
          </Box>
        </Box>
      </Modal>

      {/* Modal Geocercas*/}
      <Modal
        open={openGeocercas}
        onClose={() => setOpenGeocercas(false)}
        aria-labelledby="geocerca-modal-title"
        aria-describedby="geocerca-modal-description"
      >
        <Box sx={{ ...style, width: 1000 }}>
          {/* Botón de cierre */}
          <IconButton
            aria-label="close"
            onClick={() => setOpenGeocercas(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CancelIcon />
          </IconButton>
          <h2 id="geocerca-modal-title">Configuración de Geocercas</h2>

          <Divider
            sx={{
              borderBottomWidth: 2,
              borderColor: "red",
              marginBottom: 2,
            }}
          />

          <p id="geocerca-modal-description">
            <ListaGeocercas
              configuracion={configuracion}
              actualizarComponente={actualizarComponente}
              handleGuardarPrincipal={handleGuardarPrincipal}
              idAlerta={alertaId}
            />
          </p>
        </Box>
      </Modal>

      {/* Modal Audios*/}
      <Modal
        open={openAudios}
        onClose={() => setOpenAudios(false)}
        aria-labelledby="audio-modal-title"
        aria-describedby="audio-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 1000,
            height: "auto", // La altura se ajusta al contenido
            maxHeight: "90vh", // Limita la altura máxima al 90% de la ventana
            overflowY: "auto", // Habilita desplazamiento si el contenido es muy grande
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* Botón de cierre */}
          <IconButton
            aria-label="close"
            onClick={() => setOpenAudios(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CancelIcon />
          </IconButton>
          <h2 id="audio-modal-title">Configuración de Audios</h2>

          <Divider
            sx={{
              borderBottomWidth: 2,
              borderColor: "red",
              marginBottom: 2,
            }}
          />

          <p id="audio-modal-description">
            <ListaAudios
              configuracion={configuracion}
              actualizarComponente={actualizarComponente}
              handleGuardarPrincipal={handleGuardarPrincipal}
              idAlerta={alertaId}
            />
          </p>
        </Box>
      </Modal>

      {/* Modal Contactos */}
      <Modal
        key={alertaId}
        open={openContactos}
        onClose={() => setOpenContactos(false)}
        aria-labelledby="contactos-modal-title"
        aria-describedby="contactos-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 1000,
            height: "auto", // La altura se ajusta al contenido
            maxHeight: "90vh", // Limita la altura máxima al 90% de la ventana
            overflowY: "auto", // Habilita desplazamiento si el contenido es muy grande
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 0,
          }}
        >
          {/* Botón de cierre */}
          <IconButton
            aria-label="close"
            onClick={() => setOpenContactos(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CancelIcon />
          </IconButton>
          <h2 id="contactos-modal-title" style={{ marginBottom: "4px" }}>
            Configuracion de Contactos
          </h2>
          <p id="contactos-modal-description">
            <ModalListaContactos
              configuracion={configuracion}
              actualizarComponente={actualizarComponente}
              handleGuardarPrincipal={handleGuardarPrincipal}
              idAlerta={alertaId}
            />
          </p>
        </Box>
      </Modal>
    </>
  );
};

export default TableModule;
