// src/components/GroupingControls.js
import React from 'react';
import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
} from '@mui/material';

const GroupingControls = ({ 
  fields,
  groupBy,
  setGroupBy,
  showCount,
  setShowCount,
  showPercentage,
  setShowPercentage,
  showNested,
  setShowNested
}) => {
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>Opciones de Agrupación</Typography>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Agrupar Por</InputLabel>
          <Select
            value={groupBy || ''}
            onChange={(e) => setGroupBy(e.target.value)}
            label="Agrupar Por"
          >
            <MenuItem value="">Ninguno</MenuItem>
            {fields.map(field => (
              <MenuItem key={field} value={field}>
                {field}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {groupBy && (
          <>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={showCount}
                  onChange={(e) => setShowCount(e.target.checked)}
                />
              }
              label="Mostrar Conteo"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showPercentage}
                  onChange={(e) => setShowPercentage(e.target.checked)}
                />
              }
              label="Mostrar Porcentaje"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showNested}
                  onChange={(e) => setShowNested(e.target.checked)}
                />
              }
              label="Mostrar Filas Anidadas"
            />
          </>
        )}
      </Box>
    </Paper>
  );
};

export default GroupingControls;

// // src/components/GroupingControls.js
// import React from 'react';
// import {
//   Paper,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Checkbox,
//   FormControlLabel,
//   Box,
//   Typography,
// } from '@mui/material';

// const GroupingControls = ({ 
//   fields,
//   groupBy,
//   setGroupBy,
//   showCount,
//   setShowCount,
//   showPercentage,
//   setShowPercentage,
//   showNested,
//   setShowNested
// }) => {
//   return (
//     <Paper sx={{ p: 2, mb: 2 }}>
//       <Typography variant="h6" gutterBottom>Grouping Options</Typography>
//       <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
//         <FormControl sx={{ minWidth: 200 }}>
//           <InputLabel>Group By</InputLabel>
//           <Select
//             value={groupBy || ''}
//             onChange={(e) => setGroupBy(e.target.value)}
//             label="Group By"
//           >
//             <MenuItem value="">None</MenuItem>
//             {fields.map(field => (
//               <MenuItem key={field} value={field}>
//                 {field}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>

//         {groupBy && (
//           <>
//             <FormControlLabel
//               control={
//                 <Checkbox 
//                   checked={showCount}
//                   onChange={(e) => setShowCount(e.target.checked)}
//                 />
//               }
//               label="Show Count"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={showPercentage}
//                   onChange={(e) => setShowPercentage(e.target.checked)}
//                 />
//               }
//               label="Show Percentage"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={showNested}
//                   onChange={(e) => setShowNested(e.target.checked)}
//                 />
//               }
//               label="Show Nested Rows"
//             />
//           </>
//         )}
//       </Box>
//     </Paper>
//   );
// };

// export default GroupingControls;