import React, { useEffect } from 'react';
import { 
  Paper,
  TextField,
  Box,
  Chip,
  Typography,Button
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { motion } from 'framer-motion';

export const FiltersDatabase = ({ filtersWithTypes, filters, setFilters,onSearch }) => {

  useEffect(() => {
    const initializeDates = () => {
      filtersWithTypes.forEach(({ nombre, tipoDato }) => {
        if (tipoDato.toLowerCase() === 'date') {
          const today = new Date();
          const startOfDay = new Date(today.setHours(0, 0, 0, 0));
          const endOfDay = new Date(today.setHours(23, 59, 59, 999));
          setFilters(prev => ({
            ...prev,
            [`${nombre}_start`]: startOfDay,
            [`${nombre}_end`]: endOfDay,
          }));
        }
      });
    };

    initializeDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value,
    }));
    console.log(`Filtro actualizado: ${field} = ${value}`);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Paper 
          elevation={3}
          sx={{ p: 2, mb: 2 }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>Filters</Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexWrap: 'wrap',
              alignItems: 'center'
            }}
          >
            {filtersWithTypes
              .filter(({ visible }) => visible !== false)
              .map(({ nombre, tipoDato, label }) => (
                tipoDato.toLowerCase() === 'date' ? (
                  <Box key={nombre} sx={{ display: 'flex', gap: 1 }}>
                    <DateTimePicker
                      label={`${label} inicio`}
                      value={filters[`${nombre}_start`] || null}
                      onChange={(newValue) => handleInputChange(`${nombre}_start`, newValue)}
                      renderInput={(params) => <TextField {...params} size="small" />}
                    />
                    <DateTimePicker
                      label={`${label} final`}
                      value={filters[`${nombre}_end`] || null}
                      onChange={(newValue) => handleInputChange(`${nombre}_end`, newValue)}
                      renderInput={(params) => <TextField {...params} size="small" />}
                    />
                  </Box>
                ) : (
                  <TextField
                    key={nombre}
                    label={label}
                    variant="outlined"
                    size="small"
                    value={filters[nombre] || ''}
                    onChange={(e) => handleInputChange(nombre, e.target.value)}
                    sx={{ minWidth: 200 }}
                  />
                )
              ))}

            {/* {filtersWithTypes.map(({ nombre, tipoDato }) => (
              tipoDato.toLowerCase() === 'date' ? (
                <Box key={nombre} sx={{ display: 'flex', gap: 1 }}>
                  <DateTimePicker
                    label={`${nombre} inicio`}
                    value={filters[`${nombre}_start`] || null}
                    onChange={(newValue) => handleInputChange(`${nombre}_start`, newValue)}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                  <DateTimePicker
                    label={`${nombre} final`}
                    value={filters[`${nombre}_end`] || null}
                    onChange={(newValue) => handleInputChange(`${nombre}_end`, newValue)}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </Box>
              ) : (
                <TextField
                  key={nombre}
                  label={nombre}
                  variant="outlined"
                  size="small"
                  value={filters[nombre] || ''}
                  onChange={(e) => handleInputChange(nombre, e.target.value)}
                  sx={{ minWidth: 200 }}
                />
              )
            ))} */}
          </Box>
          <Box sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {Object.entries(filters).map(([field, value]) => (
              value && (
                <Chip
                  key={field}
                  label={
                    field.includes('_start') || field.includes('_end') 
                      ? `${field.replace('_start','').replace('_end','')}: ${new Date(value).toLocaleString()}`
                      : `${field}: ${value}`
                  }
                  onDelete={() => handleInputChange(field, null)}
                />
              )
            ))}
          </Box>

          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <Button variant="contained" color="primary" onClick={onSearch}>
              Buscar
            </Button>
          </Box>

        </Paper>
      </motion.div>
    </LocalizationProvider>
  );
};

export default FiltersDatabase;