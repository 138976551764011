import React, { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';

const MainLayout = ({ children, menuData }) => {
  const theme = useTheme();
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleSidebarToggle = () => setIsSidebarOpen(!isSidebarOpen);
  const handleSidebarMinimizeToggle = () => setIsSidebarMinimized(!isSidebarMinimized);

  const sidebarWidth = isSidebarMinimized ? 60 : 250;

  return (
    <Box sx={{
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      minHeight: '100vh',
      height: '100vh', 
      display: 'flex',
      flexDirection: 'column',
      // overflow: 'hidden' 

    }}>
      <Navbar 
        onMenuClick={handleSidebarToggle} 
        onMinimizeClick={handleSidebarMinimizeToggle} 
        isSidebarMinimized={isSidebarMinimized}
      />
      <Box sx={{ display: 'flex', flexGrow: 1, mt: '64px' }}>
        {isSidebarOpen && (
          <Sidebar 
            open={isSidebarOpen}
            menuData={menuData} 
            isMinimized={isSidebarMinimized}
          />
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            py: 3,
            px: 1,
            ml: isSidebarOpen ? `${sidebarWidth}px` : 0,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
