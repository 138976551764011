// src/services/apiService.js
import axios from "axios";
import { API_CONFIG } from "../config/apiConfig";

// Create axios instance
const apiClient = axios.create({
  baseURL: API_CONFIG.BASE_URL,
  timeout: API_CONFIG.TIMEOUT,
  headers: {
    ...API_CONFIG.HEADERS,
    "x-api-key": API_CONFIG.X_API_KEY,
  },
});

// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function formatDateLocal(date) {
  const padZero = (num) => num.toString().padStart(2, "0");
  return (
    date.getFullYear() +
    "-" +
    padZero(date.getMonth() + 1) +
    "-" +
    padZero(date.getDate()) +
    " " +
    padZero(date.getHours()) +
    ":" +
    padZero(date.getMinutes()) +
    ":" +
    padZero(date.getSeconds())
  );
}

// Response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("auth");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
/*-------------------------------------*/

// Create axios instance
const apitestcotalogos = axios.create({
  baseURL: "https://localhost:7102", //API_CONFIG.BASE_URL,
  timeout: API_CONFIG.TIMEOUT,
  headers: {
    ...API_CONFIG.HEADERS,
    "x-api-key": API_CONFIG.X_API_KEY,
  },
});

// Request interceptor
apitestcotalogos.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
apitestcotalogos.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("auth");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

// API methods
export const authService = {
  login: async (credentials) => {
    const response = await apiClient.post("/Auth/login", credentials);
    return response.data;
  },
  logout: async () => {
    const response = await apiClient.post("/Auth/Logout");
    return response.data;
  },
};

export const empresaService = {
  getEmpresas: async (id = "") => {
    try {
      id = localStorage.getItem("uuid_empresa");
      const response = await apiClient.get("/services/Empresa/coleccion", {
        params: {
          Id: id,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching empresas:", error.response?.data);
      throw error;
    }
  },
};

export const geocercaService = {
  // getGeocercas: async (uid_empresa, id = '', uid_externo = '') => {
  //   const response = await apiClient.get(`/services/Geocerca/listado-geocercas`, {
  //     params: { uid_empresa, id, uid_externo }
  //   });
  //   return response.data;
  // },

  getGeocercas: async (
    uid_empresa,
    id = "",
    uid_externo = "",
    status = "1"
  ) => {
    uid_empresa = localStorage.getItem("uuid_empresa");
    const response = await apiClient.request({
      method: "post",
      url: "/services/Geocerca/listado-geocercas",
      data: JSON.stringify({
        uid_empresa,
        id,
        uid_externo,
        status,
      }),
    });
    return response.data;
  },
  createGeocerca: async (data) => {
    const response = await apiClient.post("/services/Geocerca/agregar", data);
    return response.data;
  },
  updateGeocerca: async (data) => {
    const response = await apiClient.put("/services/Geocerca/editar", data);
    return response.data;
  },
  deleteGeocerca: async (id, type) => {
    const response = await apiClient.delete(
      `/services/Geocerca/eliminar/${id}/${type}`
    );
    return response.data;
  },
};

export const userService = {
  getProfile: async () => {
    const response = await apiClient.get("/User/Profile");
    return response.data;
  },
  updateProfile: async (data) => {
    const response = await apiClient.put("/User/Profile", data);
    return response.data;
  },
};

export const menuService = {
  getMenu: async () => {
    const response = await apiClient.get(
      "/services/Menu/coleccion?activo=true"
    );
    return response.data;
  },
};

export const vehiculoService = {
  createVehiculo: async (data) => {
    try {
      const response = await apiClient.post("/services/Vehiculo/agregar", data);
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  updateVehiculo: async (data) => {
    try {
      const response = await apiClient.put(
        "/services/Vehiculo/actualizar",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  getVehiculos: async (contactoId="", status = 1, uid_externo = "") => {
    try {
      const uidEmpresa = localStorage.getItem("uuid_empresa");
      

      const response = await apiClient.get(`/services/Vehiculo/coleccion`, {
        params: {
          "Id": contactoId,
          "UID_Empresa": uidEmpresa,
          "UID_Externo": uid_externo,
          "Status": status,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  deleteVehiculo: async (data) => {
    try {
      const response = await apiClient.delete(
        `/services/Vehiculo/eliminar/${data}`
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};

export const eventoService = {
  getEventos: async () => {
    try {
      const response = await apiClient.get("/services/evento/coleccion", {
        params: {
          activo: true,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};

export const contactoService = {
  getContactos: async (contactoId, status) => {
    const uidEmpresa = localStorage.getItem("uuid_empresa");
    const response = await apiClient.get("/services/Contacto/coleccion", {
      params: {
        Id: contactoId,
        UID_Empresa: uidEmpresa,
        Status: status,
      },
    });
    return response.data;
  },

  createContacto: async (data) => {
    try {
      const response = await apiClient.post("/services/Contacto/agregar", data);
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },

  updateContacto: async (data) => {
    try {
      const response = await apiClient.put(
        "/services/Contacto/actualizar",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },

  updateContactos: async (data) => {
    try {
      console.log(data);
      const response = await apiClient.put(
        "/services/Contacto/actualizarContactos",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },

  deleteContacto: async (id) => {
    try {
      const response = await apiClient.delete(
        `/services/Contacto/eliminar/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};
export const clasificacionService = {
  getClasificaciones: async (clasificacionId, status) => {
    const response = await apiClient.get(
      "/services/Clasificaciones/coleccion",
      {
        params: {
          id: clasificacionId,
          status: status,
        },
      }
    );
    return response.data;
  },
  createClasificacion: async (data) => {
    try {
      const response = await apiClient.post(
        "/services/Clasificaciones/agregar",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  updateClasificaciones: async (data) => {
    try {
      const response = await apiClient.put(
        "/services/Clasificaciones/actualizar",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },

  deleteClasificacion: async (id) => {
    try {
      const response = await apiClient.delete(
        `/services/Clasificaciones/eliminar/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};
export const telegramService = {
  getLink: async (id) => {
    try {
      const response = await apiClient.get(`/services/Telegram/link`, {
        headers: {
          Id: id,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};
//https://omisiones.quro.com.mx:8056/
const localApiClient = axios.create({
  baseURL: "https://omisiones.quro.com.mx:8056", //'http://localhost:8057',//''http://localhost:8057',//''http://localhost:8057',//
  timeout: 0, //API_CONFIG.TIMEOUT,
  headers: {
    "Content-Type": "application/json",
  },
  //withCredentials: true
});

export const notificacionService = {
  getNotificaciones: async () => {
    try {
      const response = await apiClient.post(
        "/services/Notificaciones/coleccion",
        { Status: 1 }
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  getNotificacionesLocal: async (startDate, endDate, limit = 100) => {
    try {
      let empresauid = localStorage.getItem("uid_externo"); //'7ca085a2-e29c-40dd-85d6-249ad45d21b2';//
      console.log("empresauid:", empresauid);
      const response = await localApiClient.get("/data/notificaciones", {
        params: {
          Fecha_gte: startDate,
          Fecha_lte: endDate,
          limit: limit,
          EmpresaUid: empresauid, //localStorage.getItem('uid_externo')
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching local notifications:", error);
      return null;
    }
  }
  ,
  getNotificacionesproductivo: async (startDate, endDate, limit = 100, skip = 0, fields = ['Message', 'Status', 'Fecha'], sort = 'Fecha',
    VehiculoUid,Tipo
  ) => {
    try {
      // Get current date
      const now = new Date();

      if (!startDate) {
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate());
        startDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0)
          .toISOString()
          .slice(0, 19)
          .replace('T', ' ');
      }

      // If endDate is not provided, set to the end of the current day
      if (!endDate) {
        const today = new Date(now);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");
      }

      const empresauid = localStorage.getItem("uid_externo");

      const response = await localApiClient.post(
        "/data_2_paged/notificaciones",
        {
          skip: skip,
          limit: limit,
          fields: fields.join(","),
          sort: sort,
          Fecha_gte: startDate,
          Fecha_lte: endDate,
          EmpresaUid: empresauid,
          VehiculoUid: VehiculoUid,
          DatosAlertaTipo: Tipo,
          //"DatosAlerta.Tipo": Tipo
        }
      );

      //console.log('response de notificaciones:', response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching local notifications:", error);
      return null;
    }
  },
  getNotificacionesOmisiones: async (
    startDate,
    endDate,
    limit = 1,
    skip = 0,
    fields = [],
    sort = "FechaHora_Notificacion",
    VehiculoUid,
    Tipo
  ) => {
    try {
      // Get current date
      const now = new Date();

      if (!startDate) {
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate());
        startDate = formatDateLocal(
          new Date(
            yesterday.getFullYear(),
            yesterday.getMonth(),
            yesterday.getDate(),
            0,
            0,
            0
          )
        );
      }

      // Si endDate no se proporciona, establecer al final del día actual
      if (!endDate) {
        const today = new Date(now);
        endDate = formatDateLocal(
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            23,
            59,
            59
          )
        );
      }

      // if (!startDate) {
      //   const yesterday = new Date(now);
      //   yesterday.setDate(now.getDate());
      //   startDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0)
      //     .toISOString()
      //     .slice(0, 19)
      //     .replace('T', ' ');
      // }

      // // If endDate is not provided, set to the end of the current day
      // if (!endDate) {
      //   const today = new Date(now);
      //   endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
      //     .toISOString()
      //     .slice(0, 19)
      //     .replace('T', ' ');
      // }

      const empresauid = localStorage.getItem("uuid_empresa");

      const response = await localApiClient.post("/notificaciones_omisiones", {
        skip: skip,
        limit: limit,
        fields: fields.join(","),
        sort: sort,
        FechaHora_Notificacion_gte: startDate,
        FechaHora_Notificacion_lte: endDate,
        UID_Empresa: empresauid,
        UID_Vehiculo: VehiculoUid,
        Tipo_Alerta: Tipo,
      });

      //console.log('response de notificaciones:', response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching local notifications:", error);
      return null;
    }
  },

  getAlertas: async () => {
    try {
      const response = await localApiClient.get(`/alertas`, {
        // params: {
        //   activo: 1,
        // },
      });
      //console.log(JSON.stringify(response.data));
      return response.data;	
    } catch (error) {	
      console.error(error.response.data);	
    }	
  },
  getVehiculo:async()=>{	
    const empresauid = localStorage.getItem('uid_externo');
    try {
      const response = await localApiClient.get(`/vehiculo/${empresauid}`);
      //console.log(JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  } 
};

export const RiesgoService = {
  getRiesgos: async (activo = 1) => {
    try {
      const response = await apiClient.get(`/services/Riesgo/coleccion`, {
        query: { activo },
      });
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};

export const alertaService = {
  createAlerta: async (data) => {
    try {
      const response = await apiClient.post("/services/Alerta/agregar", data);
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  updateAlerta: async (data) => {
    try {
      const response = await apiClient.put("/services/Alerta/editar", data);
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  getAlertList: async (filtros) => {
    try {
      const uid_empresa = localStorage.getItem("uuid_empresa");

      // Agregar uid_empresa al objeto filtros, manteniendo las demás propiedades
      const filtrosActualizados = { ...filtros, uid_empresa };

      const response = await apiClient.post(
        `/services/Alerta/listado-alertas`,
        filtrosActualizados
      );

      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  deleteAlert: async (params) => {
    try {
      const response = await apiClient.post(
        `/services/Alerta/eliminar`,
        params
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
};

export const ClasificacionEventoService = {
  AsignarEventos: async (data) => {
    try {
      const response = await apiClient.post(
        "/services/ClasificacionEvento/AgregarEventosAsignados",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  FiltrarAsignaciones: async (iud_clasificacion = "") => {
    try {
      const response = await apiClient.post(
        "/services/ClasificacionEvento/Listado-Asignaciones",
        {
          params: {
            iud_clasificacion: iud_clasificacion,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error.response.data);
    }
  },
  getClasificacionesActivas: async () => {
    const response = await apiClient.get(
      "/services/Clasificaciones/coleccion",
      {
        params: {
          status: 1,
        },
      }
    );
    return response.data;
  },
};

// Add more service modules as needed
export default apiClient;
