// Footer.js
import React from 'react';
import { Typography, Link, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

const FooterContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  textAlign: 'center',
  marginTop: 'auto',
}));

function Footer() {
  return (
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <FooterContainer>
        <Typography variant="body2">
          © {new Date().getFullYear()} Sistemas En Punto
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Link href="/politica-privacidad" color="inherit" sx={{ mr: 2 }}>
            Política de Privacidad
          </Link>
          |
          <Link href="/politica-cookies" color="inherit" sx={{ ml: 2 }}>
            Política de Cookies
          </Link>
        </Box>
      </FooterContainer>
    </motion.div>
  );
}

export default Footer;